////////////////->FILTERS<-//////////////////////////////////
export const FILTER_RADIO_SELECTED = 'FILTER_RADIO_SELECTED';
export const FILTER_DATA_CHANGED = 'FILTER_DATA_CHANGED';
export const FILTER_DATA_RESET = 'FILTER_DATA_RESET';
export const FILTER_DATA_REMOVED = 'FILTER_DATA_REMOVED';
export const FILTER_SELECTED_VALUE_CHANGED = 'FILTER_SELECTED_VALUE_CHANGED';
export const INITIALIZE_FILTERS = 'INITIALIZE_FILTERS';
export const CUSTOM_FILTER_DATA_CHANGED = 'CUSTOM_FILTER_DATA_CHANGED';
export const FILTER_SELECTED_QUERY_CHANGED = 'FILTER_SELECTED_QUERY_CHANGED';
export const FILTER_SELECTED_TYPE_CHANGED = 'FILTER_SELECTED_TYPE_CHANGED';
export const FILTER_TYPE = 'FILTER_TYPE';
/////////////////////////////////////////////////////////////

////////////////->FILTERS<-//////////////////////////////////
export const GENERIC_FILTER_RADIO_SELECTED = 'GENERIC_FILTER_RADIO_SELECTED';
export const GENERIC_FILTER_DATA_CHANGED = 'GENERIC_FILTER_DATA_CHANGED';
export const GENERIC_FILTER_DATA_RESET = 'GENERIC_FILTER_DATA_RESET';
export const GENERIC_FILTER_DATA_REMOVED = 'GENERIC_FILTER_DATA_REMOVED';
export const GENERIC_FILTER_SELECTED_VALUE_CHANGED = 'GENERIC_FILTER_SELECTED_VALUE_CHANGED';
export const GENERIC_INITIALIZE_FILTERS = 'GENERIC_INITIALIZE_FILTERS';

////////////////->SUGGESTED FILTERS<-//////////////////////////////////
export const SUGGESTED_FILTER = 'SUGGESTED_FILTER';
export const COLUMN_NAME = 'COLUMN_NAME';
export const SET_OBJECT = 'SET_OBJECT';
export const RESET_OBJECT = 'RESET_OBJECT';
export const INITIALISE_OBJECT = 'INITIALISE_OBJECT';
/////////////////////////////////////////////////////////////

////////////////->DASHBOARD_FILTERS<-//////////////////////////////////
export const DASHBOARD_FILTER_RADIO_SELECTED = 'DASHBOARD_FILTER_RADIO_SELECTED';
export const DASHBOARD_FILTER_DATA_CHANGED = 'DASHBOARD_FILTER_DATA_CHANGED';
export const DASHBOARD_FILTER_DATA_RESET = 'DASHBOARD_FILTER_DATA_RESET';
export const DASHBOARD_FILTER_DATA_REMOVED = 'DASHBOARD_FILTER_DATA_REMOVED';
export const DASHBOARD_FILTER_SELECTED_VALUE_CHANGED = 'DASHBOARD_FILTER_SELECTED_VALUE_CHANGED';
export const DASHBOARD_FILTER_SELECTED_BOOL_VALUE_CHANGED = 'DASHBOARD_FILTER_SELECTED_BOOL_VALUE_CHANGED';
export const INITIALIZE_DASHBOARD_FILTERS = 'INITIALIZE_DASHBOARD_FILTERS';
export const GENERIC_DASHBOARD_FILTER_DATA_RESET = 'GENERIC_DASHBOARD_FILTER_DATA_RESET';
export const SET_FILTER_REMOVE_FROM_LOCAL = 'SET_FILTER_REMOVE_FROM_LOCAL';
//////////////////////////////////////////////////////////////////////

/////////////////->MASTER DATA<-/////////////////////////////
export const SET_MASTER_DATA = 'SET_MASTER_DATA';
export const SET_REPORT_MASTER_DATA = 'SET_REPORT_MASTER_DATA';
export const SET_REPORT_MASTER_DATA_LOADING = 'SET_REPORT_MASTER_DATA_LOADING';
export const SET_USER_ROLES = 'SET_USER_ROLES';
export const SET_DEVICE_TYPE = 'SET_DEVICE_TYPE';
export const SET_ANALYTICS_DASHBOARD_CRM = 'SET_ANALYTICS_DASHBOARD_CRM';
/////////////////////////////////////////////////////////////

////////////////->METRICS<-//////////////////////////////////
export const METRICS_CHANGED = 'METRICS_CHANGED';
export const METRICS_RESET = 'METRICS_RESET';
export const METRICS_REMOVED = 'METRICS_REMOVED';
export const CHANGE_METRIC_ALIAS = 'CHANGE_METRIC_ALIAS';
export const CHANGE_AGGREGATE = 'CHANGE_AGGREGATE';
export const RESET_ALL_AGGREGATE = 'RESET_ALL_AGGREGATE';
export const ADD_METRIC_LINK_REPORT = 'ADD_METRIC_LINK_REPORT';
export const REMOVE_METRIC_LINK_REPORT = 'REMOVE_METRIC_LINK_REPORT';
export const REMOVE_LINKED_METRIC = 'REMOVE_LINKED_METRIC';
export const REMOVE_ALL_LINKED_REPORTS = 'REMOVE_ALL_LINKED_REPORTS';
export const INITIALIZE_METRICES = 'INITIALIZE_METRICES';
export const SET_COLUMN_WIDTH_METRICES = 'SET_COLUMN_WIDTH_METRICES';
export const SET_COLUMN_WIDTH_ALIAS_NAME = 'SET_COLUMN_WIDTH_ALIAS_NAME';
export const SET_SAVED_WIDTHS = 'SET_SAVED_WIDTHS';
export const REMOVE_METRIC_KEY = 'REMOVE_METRIC_KEY';
export const UPDATE_DUMMY_DATA = 'UPDATE_DUMMY_DATA';
/////////////////////////////////////////////////////////////

export const GENERIC_RESET_ALL_AGGREGATE = 'GENERIC_RESET_ALL_AGGREGATE';
export const GENERIC_SUMMARISATION_LIST_CHANGED = 'GENERIC_SUMMARISATION_LIST_CHANGED';
export const GENERIC_BUCKET_VALUE_CHANGED = 'GENERIC_BUCKET_VALUE_CHANGED';
export const GENERIC_SUMMARISATION_LIST_RESET = 'GENERIC_SUMMARISATION_LIST_RESET';
export const GENERIC_INITIALIZE_AGGREGATE = 'GENERIC_INITIALIZE_AGGREGATE';

////////////////->METRICS SUMMARISATION<-//////////////////////////////////
export const SUMMARISATION_LIST_CHANGED = 'SUMMARISATION_LIST_CHANGED';
export const SUMMARISATION_LIST_RESET = 'SUMMARISATION_LIST_RESET';
export const INITIALIZE_AGGREGATE = 'INITIALIZE_AGGREGATE';
export const BUCKET_VALUE_CHANGED = 'BUCKET_VALUE_CHANGED';
export const DRILLDOWN_VALUE_CHANGED = 'DRILLDOWN_VALUE_CHANGED';
export const REMOVE_DRILLDOWN = 'REMOVE_DRILLDOWN';
/////////////////////////////////////////////////////////////

////////////////->METRICS SORT<-//////////////////////////////////
export const SORT_SELECTED_LIST_CHANGED = 'SORT_SELECTED_LIST_CHANGED';
export const SORT_DATA_REMOVED = 'SORT_DATA_REMOVED';
export const SORT_DATA_RESET = 'SORT_DATA_RESET';
export const SORT_VALUE_CHANGED = 'SORT_VALUE_CHANGED';
export const INITIALIZE_SORT = 'INITIALIZE_SORT';
/////////////////////////////////////////////////////////////

////////////////->CHARTSMETADATE<-//////////////////////////////////
export const SET_CHARTS_METADATA = 'SET_CHARTS_METADATA';
export const SET_CHART_TYPE = 'SET_CHART_TYPE';
export const CHART_OPTION_VALUE_CHANGED = 'CHART_OPTION_VALUE_CHANGED';
export const CHART_CONFIG_VALUE_CHANGED = 'CHART_CONFIG_VALUE_CHANGED';
export const INITIALIZE_CHARTOPTIONS = 'INITIALIZE_CHARTOPTIONS';
export const INITIALIZE_CHARTCONFIG = 'INITIALIZE_CHARTCONFIG';
export const INITIALIZE_CONDITIONAL_FORMATTING = 'INITIALIZE_CONDITIONAL_FORMATTING';
export const SET_TABLE_PAGE_SIZE = 'SET_TABLE_PAGE_SIZE';
export const RESET_CHART_OPTIONS = 'RESET_CHART_OPTIONS';
export const SET_FIELD_VALUE = 'SET_FIELD_VALUE';
export const RESET_CHART = 'RESET_CHART';
export const FORMATTING_ACTION_COLUMN_CHANGE = 'FORMATTING_ACTION_COLUMN_CHANGE';
export const FORMATTING_ACTION_OPTION_CHANGE = 'FORMATTING_ACTION_OPTION_CHANGE';
export const FORMATTING_ACTION_VALUE_CHANGE = 'FORMATTING_ACTION_VALUE_CHANGE';
export const FORMATTING_ACTION_COLOR_CHANGE = 'FORMATTING_ACTION_COLOR_CHANGE';
export const FORMATTING_CONDITION_DATA_CHANGE = 'FORMATTING_CONDITION_DATA_CHANGE';
export const FORMATTING_CONDITION_COLUMN_CHANGE = 'FORMATTING_CONDITION_COLUMN_CHANGE';
export const FORMATTING_CONDITION_OPERATOR_CHANGE = 'FORMATTING_CONDITION_OPERATOR_CHANGE';
export const FORMATTING_SELECT_METRIC = 'FORMATTING_SELECT_METRIC';
export const FORMATTING_DELETE_METRIC = 'FORMATTING_DELETE_METRIC';
export const FORMATTING_RESET_METRIC = 'FORMATTING_RESET_METRIC';
export const FORMATTING_CONDITION_DELETE = 'FORMATTING_CONDITION_DELETE';
export const FORMATTING_CONDITION_ADD = 'FORMATTING_CONDITION_ADD';
export const RESET_FORMATTING = 'RESET_FORMATTING';
export const UPDATE_CHART_DATA_PIVOT = 'UPDATE_CHART_DATA_PIVOT';
export const SET_HTML_TEXT = 'SET_HTML_TEXT';

/////////////////////////////////////////////////////////////

/////////////////->REPORTS<-/////////////////////////////////////////
export const SELECTED_REPORT_CHANGED = 'SELECTED_REPORT_CHANGED';
export const SELECTED_DBTYPE_CHANGED = 'SELECTED_DBTYPE_CHANGED';
export const SET_IS_AGGREGATED = 'SET_IS_AGGREGATED';
export const CHART_OPTION_REMOVED = 'CHART_OPTION_REMOVED';
export const ADMIN_REPORT_SWITCH = 'ADMIN_REPORT_SWITCH';
export const CUSTOM_QUERY_STRING = 'CUSTOM_QUERY_STRING';
export const CHANGE_METRIC_STRING_MANUAL_QUERY = 'CHANGE_METRIC_STRING_MANUAL_QUERY';
export const CHANGE_MONGO_COLLECTION_NAME = 'CHANGE_MONGO_COLLECTION_NAME';
export const SELECTED_DATA_MART_TYPE_CHANGED = 'SELECTED_DATA_MART_TYPE_CHANGED';
export const UNDERLYING_CUSTOM_QUERY_STRING = 'UNDERLYING_CUSTOM_QUERY_STRING';
/////////////////////////////////////////////////////////////////////

//////////////////METADATA/////////////////////////////////////////
export const CHANGE_APPLICATION_NAME = 'CHANGE_APPLICATION_NAME';
export const RECIEVED_PARENT_CREDENTIAL = 'RECIEVED_PARENT_CREDENTIAL';
export const LANDING_PAGE_LOADED = 'LANDING_PAGE_LOADED';
export const CHANGE_LEFT_PANE_STATE = 'CHANGE_LEFT_PANE_STATE';
export const CHANGE_LEFT_PANE_VISIBILITY = 'CHANGE_LEFT_PANE_VISIBILITY';
///////////////////////////////////////////////////////////////////

//////////////////METADATA/////////////////////////////////////////
export const SET_CUSTOM_COLUMNS_GLOBAL = 'CUSTOM_COLUMNS_GLOBAL';
export const SET_CUSTOM_COLUMNS_LOCAL = 'CUSTOM_COLUMNS_LOCAL';
export const REMOVE_CUSTOM_COLUMNS_GLOBAL = 'REMOVE_CUSTOM_COLUMNS_GLOBAL';
export const REMOVE_CUSTOM_COLUMNS_LOCAL = 'REMOVE_CUSTOM_COLUMNS_LOCAL';
export const RESET_CUSTOM_COLUMNS_GLOBAL = 'RESET_CUSTOM_COLUMNS_GLOBAL';
export const RESET_CUSTOM_COLUMNS_LOCAL = 'RESET_CUSTOM_COLUMNS_LOCAL';
export const INITIALISE_REPORT_USER_TYPE = 'INITIALISE_REPORT_USER_TYPE';
export const RESET_REPORT_USER_TYPE = 'RESET_REPORT_USER_TYPE';
export const INITILISE_REPORT_DESCRIPTION = 'INITILISE_REPORT_DESCRIPTION';
export const INITIALISE_REPORT_NAME = 'INITIALISE_REPORT_NAME';
export const INITILISE_METRIC_TYPE = 'INITILISE_METRIC_TYPE';
export const UPDATE_REPORT_NAME = 'UPDATE_REPORT_NAME';
export const INITIALISE_REPORT_ID = 'INITIALISE_REPORT_ID';
export const RESET_REPORT_NAME_AND_DESCRIPTION_AND_ID = 'RESET_REPORT_NAME_AND_DESCRIPTION_AND_ID';
export const INITIALISE_REPORT_CATEGORY = 'INITIALISE_REPORT_CATEGORY';
export const DUMMY_DATA_SWITCH = 'DUMMY_DATA_SWITCH';
///////////////////////////////////////////////////////////////////

//////////////////METADATA/////////////////////////////////////////
export const NAME_CHANGED = 'NAME_CHANGED';
//////////////////////////////////////////////////////////////////
////////////////->HAVINGFILTERS<-//////////////////////////////////
export const HAVING_FILTER_RADIO_SELECTED = 'HAVING_FILTER_RADIO_SELECTED';
export const HAVING_FILTER_DATA_CHANGED = 'HAVING_FILTER_DATA_CHANGED';
export const HAVING_FILTER_DATA_RESET = 'HAVING_FILTER_DATA_RESET';
export const HAVING_FILTER_DATA_REMOVED = 'HAVING_FILTER_DATA_REMOVED';
export const HAVING_FILTER_SELECTED_VALUE_CHANGED = 'HAVING_FILTER_SELECTED_VALUE_CHANGED';
export const HAVING_INITIALIZE_FILTERS = 'HAVING_INITIALIZE_FILTERS';
export const CHANGE_HAVING_AGGREGATE = 'CHANGE_HAVING_AGGREGATE';
/////////////////////////////////////////////////////////////

/////////////////->DASHBOARD GROUP METADATA<-/////////////////////////////////////////
export const INITIALISE_DASHBOARD_GROUP = 'INITIALISE_DASHBOARD_GROUP';
export const RESET_DASHBOARD_GROUP_NAME_AND_ID = 'RESET_DASHBOARD_NAME_AND_ID';
export const CHANGE_DASHBOARD_GROUP = 'CHANGE_DASHBOARD_GROUP';
export const GET_DASHBOARD_GROUP_LIST = 'GET_DASHBOARD_GROUP_LIST';
export const SET_DASHBOARD_GROUP_TAB = 'SET_DASHBOARD_GROUP_TAB';
export const SET_DASHBOARD_METADATA = 'SET_DASHBOARD_METADATA';
export const SET_DASHBOARD_TAB = 'SET_DASHBOARD_TAB';
export const SET_VISIBLE_CHARTS = 'SET_VISIBLE_CHARTS';
export const DASHBOARD_FILTER_UPDATED = 'DASHBOARD_FILTER_UPDATED';
export const SET_FULL_SCREEN = 'SET_FULL_SCREEN';
export const SET_LAST_UPDATED_TIME = 'SET_LAST_UPDATED_TIME';
export const SET_AUTO_REFRESH = 'SET_AUTO_REFRESH';
export const SET_REFRESH_REPORTS_DATA = 'SET_REFRESH_REPORTS_DATA';
export const CHANGE_DASHBOARD_TAB = 'CHANGE_DASHBOARD_TAB';
export const SET_AUTO_RELOAD_FREQUENCY = 'SET_AUTO_RELOAD_FREQUENCY';
export const SET_APPLIED_FILTER = 'SET_APPLIED_FILTER';
export const SET_REPORT_LIST = 'SET_REPORT_LIST';
export const SET_MOBILE_REPORT_PANE_HEIGHT = 'SET_MOBILE_REPORT_PANE_HEIGHT';
export const DASHBOARD_REPORT_CHART_CONFIG = 'DASHBOARD_REPORT_CHART_CONFIG';
export const SET_EXTERNAL_META_DATA = 'SET_EXTERNAL_META_DATA';

/////////////////////////////////////////////////////////////////////

/////////////////->DOWNLOAD MODAL<-/////////////////////////////////////////
export const CHANGE_DOWNLOAD_MODAL_STATE = 'CHANGE_DOWNLOAD_MODAL_STATE';
/////////////////////////////////////////////////////////////////////

/////////////////->NAV TAB<-/////////////////////////////////////////
export const CHANGE_NAV_TAB = 'CHANGE_NAV_TAB';
export const CHANGE_SELECTED_DASHBOARD_TYPE = 'CHANGE_SELECTED_DASHBOARD_TYPE';
export const CHANGE_SELECTED_REPORT_TYPE = 'CHANGE_SELECTED_REPORT_TYPE';
export const CHANGE_LANDING_PAGE_ID = 'CHANGE_LANDING_PAGE_ID';
/////////////////////////////////////////////////////////////////////

/////////////////->CHILD REPORT MODAL DATA<-/////////////////////////////////////////
export const RESET_CHILD_REPORT_LIST = 'RESET_CHILD_REPORT_LIST';
export const ADD_CHILD_REPORT = 'ADD_CHILD_REPORT';
export const REMOVE_CHILD_REPORT = 'REMOVE_CHILD_REPORT';
export const CHILD_GLOBAL_FILTER_RADIO_SELECTED ='CHILD_GLOBAL_FILTER_RADIO_SELECTED';
export const CHILD_GLOBAL_FILTER_SELECTED_VALUE_CHANGED = 'CHILD_GLOBAL_FILTER_SELECTED_VALUE_CHANGED';
export const CHILD_GLOBAL_FILTER_RESET = 'CHILD_GLOBAL_FILTER_RESET';
export const CHILD_GLOBAL_FILTER_APPLY = 'CHILD_GLOBAL_FILTER_APPLY';
export const CHILD_GLOBAL_SELECTED_BOOL_VALUE_CHANGED = 'CHILD_GLOBAL_SELECTED_BOOL_VALUE_CHANGED';
/////////////////////////////////////////////////////////////////////

/////////////////->UNDERLYING METRICS<-/////////////////////////////////////////
export const INITIALIZE_UNDERLYING_METRICS = 'INITIALIZE_UNDERLYING_METRICS';
export const UNDERLYING_METRICS_CHANGED = 'UNDERLYING_METRICS_CHANGED';
export const UNDERLYING_METRICS_RESET = 'UNDERLYING_METRICS_RESET';
export const CHANGE_UNDERLYING_METRIC_ALIAS = 'CHANGE_UNDERLYING_METRIC_ALIAS';
/////////////////////////////////////////////////////////////////////

/////////////////->AUTOMATION DATA<-/////////////////////////////////////////
export const AUTOMATION_STATUS_CHANGED = 'AUTOMATION_STATUS_CHANGED';
export const AUTOMATION_ACTION_SELECTED = 'AUTOMATION_ACTION_SELECTED';
export const AUTOMATION_ADDITIONAL_FIELDS_CHANGED = 'AUTOMATION_ADDITIONAL_FIELDS_CHANGED';
export const AUTOMATION_INITIALISE = 'AUTOMATION_INITIALISE';
export const AUTOMATION_SCHEDULE_CHANGED = 'AUTOMATION_SCHEDULE_CHANGED';
export const RESET_AUTOMATION_DATA = 'RESET_AUTOMATION_DATA';

/////////////////->ACTION DATA<-/////////////////////////////////////////
export const ACTION_INITIALISE = 'ACTION_INITIALISE';
export const ACTION_CHANGED = 'ACTION_CHANGED';

/////////////////->DASHBOARD SEARCH FILTERS FOR MOBILE<-/////////////////////////////////////////
export const MOBILE_DASHBOARD_SEARCH_FILTERS = 'MOBILE_DASHBOARD_SEARCH_FILTERS';
export const SWITCH_SEARCH_VISIBILITY = 'SWITCH_SEARCH_VISIBILITY';
