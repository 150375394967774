import { Tabs } from 'antd';
import React, { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createUseStyles } from 'react-jss';
import CreateQueryTopBar from './createQueryTopBar';
import DataSetupSection from './dataSetupSection';
import ChartOptionSection from './chartOptionSection';
import AutomationSection from './AutomationSection';
import { ReactComponent as ChartSetupSVG } from 'src/assets/ChartSetup.svg';
import { ReactComponent as DataSetupSVG } from 'src/assets/DataSetup.svg';
import { ReactComponent as StickStarSVG } from 'src/assets/StickStar.svg';
import CreateQueryBottomBar from './createQueryBottomBar';
import Icon, { DownloadOutlined } from '@ant-design/icons';
import UnderlyingDataSection from './UnderlyingDataSection';
import lodashUnionBy from 'lodash/unionBy';
import lodashPick from 'lodash/pick';
import CustomReportSwitch from './CustomReportSwitch';
import CustomQueryDataSetupSection from './CustomQueryDataSetupSection';
import { INITIALIZE_UNDERLYING_METRICS, SET_HTML_TEXT } from 'src/reduxActions/actionNameEnums';
import {
  VISUALIZE_TABS,
  permissionTypes,
} from 'src/constants';
import { isManualQueryReport } from 'src/components/common/utils/utils';
import HtmlReportType from './htmlReportType';
import { isReportTypeHtmlEditior } from 'src/components/utils/utils';
import { getPermissionType } from 'src/components/componentHelper';

const { CHART, DATA, DOWNLOAD, AUTOMATION } = VISUALIZE_TABS;

const useStyles = createUseStyles({
  createReportSection: {
    padding: '0px',
    margin: '0px',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 0 10px 0 rgba(0,0,0,0.15)',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  createReportBody: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    margin: '10px',
  },
  tabPane: {
    display: 'flex',
    flexDirection: 'column',
    margin: '-6px 10px',
  },
  tabPaneIconContainer: {
    textAlign: 'center',
  },
  tabs: {
    padding: '0 1rem',
    overflowY: 'auto',
    '& .ant-tabs-tab + .ant-tabs-tab': {
      margin: '0 0 0 20px',
    },
  },
});

const CreateReportSection = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    reportObject,
    submitReport,
    setReset,
    aggregate,
    filters,
    sortBy,
    havingFilters,
    initialSuggestedFilters = [],
    isEditing,
    underlyingMetrics,
  } = props;
  const [activeTab, setActiveTab] = React.useState('data');
  const enableDummyDataSwitch = useSelector((state:any) => state.reportMetaData.dummyDataSwitch);
  const userType = useSelector((state:any) => state?.masterData?.userConfig?.typeOfUser);
  const employeePermissions = useSelector((state:any) => state?.masterData?.userConfig?.employeePermissions) || [];

  const fillUnderlyingColumnsState = (
    key,
    aggregate,
    selectedUnderlyingMetrics,
  ) => {
    if (key === 'download' && !selectedUnderlyingMetrics.length) {
      const defaultUnderlyingMetrics = lodashUnionBy(
        aggregate.metricsMetaData.selectedMetrics,
        aggregate.selectedAggregate,
        'value',
      );
      const requiredDefaultUnderlyingMetrics = defaultUnderlyingMetrics.map(
        (underlyingMetric) =>
          lodashPick(underlyingMetric, [
            'alias',
            'prettyName',
            'type',
            'value',
          ]),
      );
      dispatch({
        type: INITIALIZE_UNDERLYING_METRICS,
        payload: requiredDefaultUnderlyingMetrics,
      });
    }
  };

  const onTabChange = (key) => {
    setActiveTab(key);
    fillUnderlyingColumnsState(
      key,
      aggregate,
      underlyingMetrics.selectedUnderlyingMetrics,
    );
  };

  const isShipsyInternalUser = useSelector(
    (state: any) => state?.masterData?.userConfig?.isShipsyInternalUser,
  );

  let permissionType = 'user';
  if(userType !== undefined){
    permissionType = getPermissionType(userType);
  }

  const isTabPaneDisabled = (tabType) => {
    if (tabType === DATA) {
      return false;
    }
    if (tabType === CHART) {
      return false;
    }
    if (tabType === DOWNLOAD) {
      return (
        isReportTypeHtmlEditior(reportObject.selectedReport) ||
        enableDummyDataSwitch
      );
    }
    if (tabType === AUTOMATION) {
      return (
        (permissionType !== permissionTypes.SUPERADMIN && !employeePermissions.includes('BI_AUTOMATION'))||
        isReportTypeHtmlEditior(reportObject.selectedReport)||
        isManualQueryReport(reportObject.selectedDataMartType)
      );
    }
    return true;
  };

  return (
    <div className={classes.createReportSection}>
      {isShipsyInternalUser &&
        isManualQueryReport(reportObject.selectedDataMartType) && (
          <CustomReportSwitch setReset={setReset} reportObject={reportObject} />
        )}
      <CreateQueryTopBar reportObject={reportObject} setReset={setReset} />
      <Tabs
        activeKey={activeTab}
        onChange={onTabChange}
        type="line"
        size="middle"
        className={classes.tabs}
      >
        <Tabs.TabPane
          disabled={isTabPaneDisabled(DATA)}
          tab={
            <div className={classes.tabPane}>
              <div className={classes.tabPaneIconContainer}>
                <Icon
                  style={{ margin: 0 }}
                  component={(iProps) => <DataSetupSVG {...iProps} />}
                />
              </div>
              <div>Data</div>
            </div>
          }
          key="data"
        >
          {!isManualQueryReport(reportObject.selectedDataMartType) &&
            !isReportTypeHtmlEditior(reportObject.selectedReport) && (
              <DataSetupSection
                setReset={setReset}
                reportObject={reportObject}
                aggregate={aggregate}
                filters={filters}
                sortBy={sortBy}
                havingFilters={havingFilters}
                initialSuggestedFilters={initialSuggestedFilters}
                isEditing={isEditing}
                underlyingMetrics={underlyingMetrics}
              />
            )}
          {isManualQueryReport(reportObject.selectedDataMartType) && (
            <CustomQueryDataSetupSection
              reportObject={reportObject}
              filters={filters}
              isEditing={isEditing}
            />
          )}
          {isReportTypeHtmlEditior(reportObject.selectedReport) && (
            <HtmlReportType />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane
          disabled={isTabPaneDisabled(CHART)}
          tab={
            <div className={classes.tabPane}>
              <div className={classes.tabPaneIconContainer}>
                <Icon
                  style={{ margin: 0 }}
                  component={(iProps) => <ChartSetupSVG {...iProps} />}
                />
              </div>
              <div>Chart</div>
            </div>
          }
          key="charts"
        >
          <ChartOptionSection reportObject={reportObject} />
        </Tabs.TabPane>
        <Tabs.TabPane
          disabled={isTabPaneDisabled(DOWNLOAD)}
          tab={
            <div className={classes.tabPane}>
              <div className={classes.tabPaneIconContainer}>
                <DownloadOutlined style={{ margin: 0 }} />
              </div>
              <div>Download</div>
            </div>
          }
          key="download"
        >
          <UnderlyingDataSection
            underlyingMetrics={underlyingMetrics}
            reportObject={reportObject}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          disabled={isTabPaneDisabled(AUTOMATION)}
          tab={
            <div className={classes.tabPane}>
              <div className={classes.tabPaneIconContainer}>
                <Icon
                  style={{ margin: 0 }}
                  component={(iProps) => <StickStarSVG {...iProps} />}
                />
              </div>
              <div>Automation (Beta)</div>
            </div>
          }
          key="automation"
        >
          <AutomationSection reportObject={reportObject} />
        </Tabs.TabPane>
      </Tabs>
      <div style={{ marginTop: 'auto' }}>
        <CreateQueryBottomBar
          reportObject={reportObject}
          submitReport={submitReport}
        />
      </div>
    </div>
  );
};

export default memo(CreateReportSection);
