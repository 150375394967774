import { useLazyQuery } from '@apollo/client';
import { message, notification } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment-timezone';

import { SAVEREPORT } from '../visualisationPaneCompponents/graphqlQuery';
import { useSubmissionData } from './dataSubmissionHook';
import { useHistory } from 'react-router-dom';
import React from 'react';
import useChartOptions from 'src/components/common/hooks/useChartOptions';
import { useSelector, useDispatch } from 'react-redux';
import { SET_SAVED_WIDTHS, AUTOMATION_INITIALISE } from 'src/reduxActions/actionNameEnums';
import { GET_SCHEDULERS } from 'src/api/routes';
import useLazyAPI from 'src/components/common/hooks/useLazyAPI';
import { isManualQueryReport } from 'src/components/common/utils/utils';

const TAB_TYPES = {
    default: 'Report',
    metric: 'Metric',
};

const useSaveReport = (initialValues, options: any = {}) => {
    const reportIDStoreValue = useSelector((state: any) => state.reportMetaData.reportID);
    const suggestedFilterStrings = useSelector((state: any) => state.reportMetaData.suggestedFilterStrings) || [];
    const selectedDataMartType = useSelector((state: any) => state.reportMetaData.dataMartType);
    const objectValue = useSelector((state:any) => state.reportMetaData.object) || [];
    const [showSaveReportDrawer, setShowSaveReportDrawer] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const dataHook = useSubmissionData(options);
    const [reportName, setReportName] = useState(initialValues.reportName || '');
    const [reportCategory, setReportCategory] = useState(initialValues.reportCategory || '');
    const [tab, setTab] = useState(initialValues.tab || 'default');
    const [reportDescription, setReportDescription] = useState(initialValues.reportDescription || '');
    const [metricType, setMetricType] = useState(initialValues.metricType || '');
    const [savePreference, setSavePreference] = useState(null);
    const optionList = useChartOptions();
    const isDefault = useSelector((state:any) => state.masterData?.organisationConfig?.isDefaultEditable || false);
    const automationData = useSelector((state:any) => state.automationData) || {};
    const timezone = useSelector((state: any) => state?.masterData?.organisationConfig?.timezone);
    const selectedReport = useSelector((state:any) => state.reportMetaData.selectedReport);
    const setReportNameAndDescription = () => {
        if(savePreference === 'saveExisting'){
            setReportName(initialValues.reportName);
            setReportDescription(initialValues.reportDescription);
            setReportCategory(initialValues.reportCategory);
            setMetricType(initialValues.metricType);
        }
    };
    const tabType = TAB_TYPES[tab];

    useEffect(setReportNameAndDescription, [savePreference, showSaveReportDrawer]);

    const [getSchedulers] = useLazyAPI(
        GET_SCHEDULERS,
        {
            type: 'POST',
            onCompleted: (response) => {
                if (Array.isArray(response?.schedules) && response?.schedules?.length) {
                    const automationData = { ...response.schedules[0] };

                    if (automationData.intervalType) {
                        automationData.selectedInterval = automationData.intervalType;
                    }

                    dispatch({ type: AUTOMATION_INITIALISE, payload: automationData });
                }
            },
        },
    );

    const [submitSaveQuery, { loading: savingInProgress, data }] = useLazyQuery(SAVEREPORT, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            message.success({key:'savingReport', content: `${tabType} Saved`, duration: 0.5});
            setShowSaveReportDrawer(false);
            notification['success']({
                message: `${tabType} Saved`,
                description:
                <div style={{ cursor: 'pointer', color: '#006EC3'}}>
                  {`Click here to see the ${tabType}s`}</div>,
                onClick: () => {
                  history.push(tab === 'metric' ? '/metrics' : '/reports');
                },
              });

            // fetching the automation schedule id when automation schedule is getting created for first time
            if (automationData.isEnabled && !automationData._id && reportIDStoreValue && options.refreshAutomationSchedules) {
                getSchedulers({
                    variables: {
                        filters: [{
                            key: 'entityId',
                            data: reportIDStoreValue,
                        }],
                        entityType: 'reportAutomation',
                    },
                });
            }
        },
        onError: data => {
            message.error({key:'savingReport', content: `Failed to Save ${tabType}`});
        },
    });

    const clearData = () => {
        setReportName(''),
        setReportDescription('');
        setMetricType('');
        setReportCategory('');
        setTab(tab);
    };
    const toggleDrawerPosition = () => setShowSaveReportDrawer(!showSaveReportDrawer);

    useEffect(() => {
        if(!showSaveReportDrawer) {
            clearData();
        }
    }, [showSaveReportDrawer]);

    const onSubmit = () => {
        let data;
        if (isManualQueryReport(selectedDataMartType)) {
            data = dataHook.getCustomReportSubmissionVariables();
        } else {
            data = dataHook.getSubmissionVariables();
        }
        if(!reportName){
            return message.error(`Please Provide ${tabType} Name`);
        }
        if(!reportDescription){
            return message.error(`Please Provide ${tabType} Description`);
        }
        if(isDefault){
            if(!reportCategory){
                return message.error(`Please Provide ${tabType} Category`);
            }
        }

        const { automationData } = data.variables;

        if (automationData?.isEnabled && !automationData?.automation?.action) {
            return message.error('Automation: Please select Action');
        }

        const currentAutomationScheduleTime = moment(new Date(automationData.startTimestamp)).tz(timezone).toString();
        let initialAutomationScheduleTime;
        if (initialValues.automationSchedules?.length) {
            initialAutomationScheduleTime = moment(new Date(initialValues.automationSchedules[0]?.startTimestamp)).tz(timezone).toString();
        }

        if (
            initialAutomationScheduleTime !== currentAutomationScheduleTime &&
            moment(new Date(automationData.startTimestamp)).tz(timezone, true).utc().isBefore(moment.utc())
        ) {
            return message.error('Automation: Start date and time should be greater than current date and time');
        }

        if(data.validationPassed){
            const variables = {
              name: reportName,
              description: reportDescription,
              reportType: data.variables.reportType,
              metrices: data.variables.metrices,
              filters: data.variables.filters,
              havingFilters: data.variables.havingFilters,
              pageSize: data.variables.pageSize,
              chartConfig: data.variables.chartConfig,
              chartType: data.variables.chartType,
              customMetrices: data.variables.customMetrices,
              customAggregateMetrices: data.variables.customAggregateMetrices,
              chartOptions: optionList,
              suggestedFilterStrings,
              isNew: savePreference !== 'saveExisting',
              tab,
              category: reportCategory,
              underlyingMetrics: data.variables.underlyingMetrics,
              formatting: JSON.stringify(data.variables.formatting),
              isAggregate: data.variables.isAggregate,
              actions: data.variables.actions,
              dataMartType: data.variables.dataMartType,
              isAdminReport: data.variables.isAdminReport,
              queryString: data.variables.queryString,
              htmlData: data.variables.htmlData,
              mongoCollectionName: data.variables.mongoCollectionName,
              underlyingQueryString: data.variables.underlyingQueryString,
            };
            const updatedArray = variables.metrices.map(obj => ({
                ...obj,
                columnWidth: obj.columnWidth !== null && obj.columnWidth !== undefined ? obj.columnWidth : 150,
            }));
            variables.metrices = updatedArray;
            if(reportIDStoreValue){
                variables['id'] = reportIDStoreValue;
            }
            if(tab === 'metric') {
                variables['object'] = objectValue;
                variables['metricType'] = metricType;
            }
            if (data.variables.automationData) {
                variables['automationSchedules'] = [data.variables.automationData];
            }
            const metricValues = data?.variables?.metrices?.map(metric => metric.metric);
            dispatch({
                type: SET_SAVED_WIDTHS,
                payload: metricValues,
            });

            message.loading({key:'savingReport', content: `Saving ${tabType}`});
            return submitSaveQuery({variables});
        }
    };

    const values = {
        reportName,
        reportDescription,
        reportCategory,
        metricType,
    };

    const onChange = {
        setReportName,
        setReportDescription,
        setReportCategory,
        setMetricType,
    };

    return {
        values,
        onChange,
        onSubmit,
        toggleDrawerPosition,
        savingInProgress,
        showSaveReportDrawer,
        savePreference,
        setSavePreference,
    };
};
export default useSaveReport;
