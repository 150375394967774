import React, { memo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Empty } from 'antd';
import { Pie } from '@ant-design/plots';
import PieConfigs from './ConfigCharts/configPie';

const PieViz = (props) => {
  const {
    formData,
    chartData,
    chartConfig,
    updateChartRef,
    visualizeMetaData,
    chartInteractionHook,
    showUnderlyingDataDownload,
    reportId,
    chartsFromDashboard,
  } = props;
  const plotRef = useRef<any>(null);
  const pieConfig = PieConfigs({chartConfig, formData, chartsFromDashboard, chartData});
  const enableDummyDataSwitch = useSelector(
    (state: any) => state.reportMetaData.dummyDataSwitch,
  );

  React.useEffect(() => {
    if (plotRef.current) {
      plotRef.current.on('element:click', async (...args) => {
        const columnData = args[0]?.data?.data;
        const { x, y } = args[0]?.gEvent;
        chartInteractionHook.setPopOverPosition({ x, y });
        const showChildReport = visualizeMetaData?.columnsData[columnData.name]
          ?.linkedReport?.reportId
          ? true
          : false;
        await chartInteractionHook.onChartColumnClick({
          showChildReport,
          showUnderlyingDataDownload,
          reportData: { visualizeMetaData, columnData },
          reportId,
          enableDummyDataSwitch,
        });
      });
    }
  }, [enableDummyDataSwitch, reportId]);

  const onReady = async (plot) => {
    plotRef.current = plot;
    updateChartRef(plot);
  };
  const config: any = {
    onReady,
    innerRadius: formData.doughnut ? 0.6 : 0,
    angleField: formData.angleField,
    colorField: formData.colorField,
    ...pieConfig,
  };

  return chartData.length > 0 ? <Pie {...config} /> : <Empty />;
};

const areEqual = (prevProps, nextProps) => {
  // additional props in parent like classes and chartOptions
  // were causing a re-render
  if (
    prevProps.formData === nextProps.formData &&
    prevProps.chartData === nextProps.chartData
  ) {
    return true; // do not re-render
  }
  return false; // will re-render
};

export default memo(PieViz, areEqual);
