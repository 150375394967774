import {
  BarChartOutlined,
  PieChartOutlined,
  LineChartOutlined,
  FileExcelOutlined,
  SisternodeOutlined,
  CopyOutlined,
  CheckOutlined,
} from '@ant-design/icons';

export const mailTypes = {
    TEXT: 'text',
    CXO: 'cxo',
    MJML: 'mjml',
};

export const entityTypes = {
    REPORT: 'report',
    DASHBOARD_GROUP: 'dashboardGroup',
    CXO: 'cxo',
};

export const routeEntityMap = {
    reports: 'report',
    dashboard: 'dashboardGroup',
    cxo: 'cxo',
};

export const entityRouteMap = {
    report: 'reports',
    dashboardGroup: 'dashboard',
    cxo: 'cxo',
};

export const frequencyTypes = {
    YEAR: 'year',
    MONTH: 'month',
    DAY: 'day',
    WEEK: 'week',
    HOUR: 'hour',
    MINUTE: 'minute',
};

export const weekdays = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
];

export const weekends = [
    'Saturday',
    'Sunday',
];

export const intervals = {
    DAILY : 'daily',
    WEEKLY_DAY : 'weekly_day',
    MONTHLY_DAY : 'monthly_day',
    YEARLY_DAY: 'yearly_day',
    WEEKLY_WEEKDAYS: 'weekly_weekdays',
    CUSTOM: 'custom',
    HOURLY: 'hourly',
    MINUTELY: 'minutely',
};

export const hourlyIntervals = {
    ONE_HOUR: '1_hours',
    TWO_HOURS: '2_hours',
    FOUR_HOURS: '4_hours',
    SIX_HOURS: '6_hours',
    TWELVE_HOURS: '12_hours',
};

export const schedulerIntervals = [
    { value: intervals.DAILY},
    { value: intervals.WEEKLY_DAY },
    { value: intervals.MONTHLY_DAY },
    { value: intervals.YEARLY_DAY },
    { value: intervals.WEEKLY_WEEKDAYS },
    { value: intervals.CUSTOM },
];

export const iconMap = {
    bar: BarChartOutlined,
    pie: PieChartOutlined,
    line: LineChartOutlined,
};
export const timeFormat = 'HH:mm';
export const dateFormat = 'dddd, MMM Do YYYY';
export const dateTimeFormat = 'dddd, MMM Do YYYY HH:mm:ss';
export const dateHourMinFormat = 'dddd, MMM Do YYYY HH:mm';
export const hoursFormat = 'HH';

export const chartInteractionsList = {
  childReport: {
    key: 'CHILD_REPORT',
    title: 'Child report',
    icon: SisternodeOutlined,
  },
  underlyingDataDownload: {
    key: 'UNDERLYING_DATA_DOWNLOAD',
    title: 'Download underlying data',
    icon: FileExcelOutlined,
  },
  copyToClipBoard: {
    key: 'COPY_VALUE',
    title: 'Copy value',
    icon: CopyOutlined,
  },
  copied:{
    key: 'COPIED',
    title: 'Copied',
    icon: CheckOutlined,
  },
};

export const dummyDataConst = {
  Min: 'Min',
  Max: 'Max',
  CATEGORICAL: 'CATEGORICAL',
  NUMBERS: 'NUMBERS',
  DATETIME: 'DATE TIME',
  RANGE: 'RANGE',
};

export const filterTimeFormats = {
  hour: 'Do MMM YYYY H:mm',
  day: 'D MMM YYYY',
  week: 'D MMM YYYY',
  month: 'MMM YYYY',
  quarter: 'MMM YYYY',
  year: 'YYYY',
};

export const projectNames = {
  LOGISTICS: 'logistics',
  EXIM: 'exim',
  CUSTOMERLOGISTICS: 'customer-logistics',
  TRANSPORTERPORTAL: 'transporter-portal',
};

export const permissionTypes = {
  USER: 'user',
  ADMIN: 'admin',
  SUPERADMIN: 'super_admin',
};
export const buckets = ['year', 'quarter', 'month', 'week', 'day', 'hour'];

export const importTypes = {
  DASHBOARD: 'Dashboard',
  REPORT: 'Report',
};

export const dashboardFilterTypes = {
  EXHAUSTIVE: 'exhaustive',
  FROZEN: 'freeze',
  SIMPLE: 'simple',
};

export const numberFilter = {
  numberIsGreaterThan: '>',
  numberIsLessThan: '<',
  numberIsEqualTo: '=',
  numberIsNotEqualTo: '!=',
  numberIsNull: 'Null',
  numberIsNotNull: 'Not Null',
};
export const DEFAULT_THEME = 'theme_1';

export const filterType = {
  BOOLEAN: 'boolean',
  NUMBER: 'number',
  TEXT: 'text',
  TIMESTAMPZ: 'timestampz',
  DATE: 'date',
};

export const dbTypes = {
  REDSHIFT: 'redshift',
  POSTGRESQL: 'postgresql',
  MONGO: 'mongo',
  ELASTIC_SEARCH: 'elasticSearch',
};

export const VISUALIZE_TABS = {
  DATA: 'DATA',
  CHART: 'CHART',
  DOWNLOAD: 'DOWNLOAD',
  AUTOMATION: 'AUTOMATION',
};

export const DATAMART_TYPES = {
  MANUAL_QUERY: 'manualQuery',
};

export const HTML_EDITOR_REPORT_TYPE = 'htmlEditor';

export const ALLOW_SANATIZE_TAGS_HTML = ['div', 'iframe', 'img', 'h1'];

export const ALLOW_SANATIZE_ATTRS_HTML = ['src', 'width', 'height', 'style', 'frameborder', 'scrolling'];

export const AUTO_RUN_REPORT_DISABLE = ['htmlEditor'];

export const DEFAULT_HTML_VALUE = `<!--<div style="height: 100%; overflow:hidden">-->
<!--  <iframe-->
<!--      scrolling = "no"-->
<!--      src=""-->
<!--      width="100%" -->
<!--      height="100%" -->
<!--      frameborder="0"-->
<!--      style="display: block;  transform: scale(1);">-->
<!--  </iframe>-->
<!--  <img src="example.jpg" width="280" height="90" style="margin-right: 20px;">-->
<!--</div>-->`;

export const dashboardGroupTypes = {
  USER: 'user',
  CXO: 'cxo',
  DEFAULT: 'default',
};

export const autoReloadFrequencyOptionsMin = [
  { label: '1 m', value: 60000 },
  { label: '2 m', value: 120000 },
  { label: '5 m', value: 300000 },
  { label: '10 m', value: 600000 },
];

export const autoReloadFrequencyOptionsSec = [
  { label: '15 s', value: 15000},
  { label: '30 s', value: 30000},
];

export const DEFAULT_AUTO_RELOAD_FREQUENCY = 300000;

export const NEW_RELIC_CHART_OPTIONS = [
  { value: 'AREA', prettyName: 'Area' },
  { value: 'BAR', prettyName: 'Bar' },
  { value: 'BASELINE', prettyName: 'Baseline' },
  { value: 'BILLBOARD', prettyName: 'Billboard' },
  { value: 'BULLET', prettyName: 'Bullet' },
  { value: 'EMPTY', prettyName: 'Empty' },
  { value: 'EVENT_FEED', prettyName: 'Event Feed' },
  { value: 'FUNNEL', prettyName: 'Funnel' },
  { value: 'HEATMAP', prettyName: 'Heatmap' },
  { value: 'HISTOGRAM', prettyName: 'Histogram' },
  { value: 'JSON', prettyName: 'JSON' },
  { value: 'LINE', prettyName: 'Line' },
  { value: 'MARKDOWN', prettyName: 'Markdown' },
  { value: 'PIE', prettyName: 'Pie' },
  { value: 'SCATTER', prettyName: 'Scatter' },
  { value: 'STACKED_HORIZONTAL_BAR', prettyName: 'Stacked Horizontal Bar' },
  { value: 'TABLE', prettyName: 'Table' },
  { value: 'TRAFFIC_LIGHT', prettyName: 'Traffic Light' },
  { value: 'VERTICAL_BAR', prettyName: 'Vertical Bar' },
];

const defaultDashboardChartSizes = {
  number: { h: 4, w: 3 },
  bar: { h: 8, w: 6 },
  pie: { h: 8, w: 6 },
  pivotTable: { h: 8, w: 6 },
  line: { h: 8, w: 12 },
  table: { h: 10, w: 6 },
  progressBar: { h: 8, w: 6 },
  html: { h: 8, w: 6 },
};

export const getDashboardChartSizes = (key: number) => {
  switch (key) {
    case 12:
      return defaultDashboardChartSizes;
    case 256:
      return {
        number: { h: 64, w: 64 },
        bar: { h: 100, w: 128 },
        pie: { h: 100, w: 128 },
        pivotTable: { h: 100, w: 128 },
        line: { h: 100, w: 256 },
        table: { h: 100, w: 128 },
        progressBar: { h: 100, w: 128 },
        html: { h: 100, w: 128 },
      };
    default:
      return defaultDashboardChartSizes;
  }
};

export const LAYOUT_VIEW_TYPES = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
};
