import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector, useDispatch } from 'react-redux';
import { getMapping, getFormattedColumns } from '../utils/visualisationUtils';
import { SET_FIELD_VALUE } from 'src/reduxActions/actionNameEnums';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  value: {
    color: '#262626',
    fontSize: '28px',
    fontWeight: 'bold',
    textAlign: 'center',
    letterSpacing: '-0.05em',
    minWidth: '48px',
    padding: '8px 18px',
    borderRadius: '4px',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    height: '48px',
    display: 'flex',
    border: '1px solid #D9D9D9',
    backgroundColor: '#F6F2F1',
    cursor: 'pointer',
  },
  title: {
    color: '#595959',
    fontSize: '14px',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  numberTile: {
    borderRadius: '0.25rem',
    padding: '0.5rem',
    width: '30%',
    minWidth: '15rem',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '0.5%',
    gap: '.5em',
    alignSelf: 'center',
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%', // Take full width of the main container
    flexDirection: 'row',
    padding: '12px',
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.20)',
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: '#ffffff',
    margin: '6px 12px',
    height: '64px !important',
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '70%',
    textAlign: 'left',
    color: '#595959',
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end', // Align text to the center vertically
    justifyContent: 'center',
    width: '30%',
    textAlign: 'center',
    padding: 10,
    color: '#262626',
  },
  rightContainerValue: {
    borderRadius: '25%',
    minWidth: '35px',
    fontWeight: '600',
    cursor: 'pointer',
  },
});

const EmptyRow = ({ height }) => (
  <div
    style={{
      height,
      backgroundColor: 'transparent',
    }}
  ></div>
);

const NumberViz = (props) => {
  const {
    chartData,
    chartConfig,
    chartsFromDashboard,
    formatting = [],
    formData,
    visualizeMetaData,
    singleNumber = false,
    chartInteractionHook,
    showUnderlyingDataDownload,
    reportId,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const chartConfigStore = useSelector(
    (state: any) => state.chartsMetaData.chartConfig,
  );
  const enableDummyDataSwitch = useSelector((state:any) => state.reportMetaData.dummyDataSwitch);
  const data = chartsFromDashboard ? chartConfig : chartConfigStore;
  const showLabel = data?.graphic?.label?.data ?? true;
  const showTooltip = data?.graphic?.tooltip?.data ?? true;
  const showNumberVertically =
    data?.graphic?.verticalRepresentation?.data ?? false;
  const commaSeparatorType =
    data?.graphic?.commaSeparator?.data ?? 'international';
  const getFill = (type) => {
    const formattedColumns = getFormattedColumns({
      formatting,
      formData,
    });
    const mapping: any = [];
    Object.entries(formattedColumns).map(([key, value]) => {
      const map = getMapping({
        visualizeMetaData,
        formData,
        formatting,
        totalData: chartData,
        fieldKey: key,
        fieldName: 'value',
        type,
        isNumberChart: true,
      });
      mapping.push(map);
    });
    return mapping;
  };

  const textColor = getFill('textColor');
  const background = getFill('backgroundColor');

  const onClickNumber = async (dataPoint, event) => {
    const parentContainer = event.currentTarget.closest('.charts-container').getBoundingClientRect();
    const { x: parentX, y: parentY } = parentContainer;
    const { clientX: x, clientY: y } = event;
    const popOverPositionX = x - parentX;
    const popOverPositionY = y - parentY;
    chartInteractionHook.setPopOverPosition({ x: popOverPositionX, y: popOverPositionY });

    const { value, title, metric } = dataPoint;
    const rawData: any = [];

    let columnData;
    let showChildReport;
    const valuesArray = title.split(', ');

    if (formData.meta) {
      const name = formData.meta.filter((item) => item.metric === metric)[0].name;
      showChildReport = visualizeMetaData?.columnsData[name]?.linkedReport
        ?.reportId
        ? true
        : false;
      rawData[name] = value;
      const formDataWithoutAggregates = formData.meta.filter(item => !item.operation);
      formDataWithoutAggregates.forEach((item, idx) => {
        rawData[item.name] = valuesArray[idx];
      });
      columnData = { rawData, name };
    }

    await chartInteractionHook.onChartColumnClick({
      showChildReport,
      showUnderlyingDataDownload,
      reportData: { visualizeMetaData, columnData },
      reportId,
      enableDummyDataSwitch,
    });

    dispatch({
      type: SET_FIELD_VALUE,
      payload: value,
    });
  };

  return (
    <div className={classes.container}>
      {showNumberVertically && <EmptyRow height={5} />}
      {chartData.map((dataPoint) => {
        const metric = dataPoint.metric;
        formatting.map((condition, i) => {
          if (condition?.rules[0]?.conditions[0]?.columns[0] === metric) {
            dataPoint.mapIndex = i;
          }
        });
        let fillColor = '#000000';
        let backgroundColor = '#F6F2F1';
        if (dataPoint.mapIndex !== undefined) {
          fillColor = textColor[dataPoint.mapIndex]?.mapping(
            dataPoint.value,
            dataPoint,
          )?.fill;
          backgroundColor = background[dataPoint.mapIndex]?.mapping(
            dataPoint.value,
            dataPoint,
          )?.fill;
        }
        const locale =
          commaSeparatorType === 'international'
            ? 'en-US'
            : commaSeparatorType === 'indian'
            ? 'en-IN'
            : null;
        return (
          <>
            {showNumberVertically ? (
              <div className={classes.mainContainer}>
                <div className={classes.leftContainer}>
                  <span>{dataPoint.title}</span>
                </div>
                <div className={classes.rightContainer}>
                  <span
                    className={classes.rightContainerValue}
                    style={{ color: fillColor, backgroundColor }}
                    onClick = {(event) => onClickNumber(dataPoint, event)}
                  >
                    {locale
                      ? dataPoint.value.toLocaleString(locale)
                      : dataPoint.value}
                  </span>
                </div>
              </div>
            ) : (
              <div
                className={classes.numberTile}
                style={{
                  paddingTop: singleNumber ? '0rem' : '0.5rem',
                }}
                title={showTooltip ? dataPoint.title : null}
              >
                <div
                  className={classes.value}
                  style={{ color: fillColor, backgroundColor }}
                  onClick = {(event) => onClickNumber(dataPoint, event)}
                >
                  <span>
                    {locale
                      ? dataPoint.value.toLocaleString(locale)
                      : dataPoint.value}
                  </span>
                </div>
                {showLabel ? (
                  <div className={classes.title} style={{ color: fillColor }}>
                    {dataPoint.title}
                  </div>
                ) : null}
              </div>
            )}
          </>
        );
      })}
      {showNumberVertically && <EmptyRow height={5} />}
    </div>
  );
};

export default NumberViz;
