/* eslint-disable no-cond-assign */
import { CHANGE_APPLICATION_NAME, RECIEVED_PARENT_CREDENTIAL, SET_EXTERNAL_META_DATA } from 'src/reduxActions/actionNameEnums';
import lodashOmit from 'lodash/omit';
import lodashIsEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import initialChartConfig from 'src/components/pages/chartBuilder/createQueryComponents/chartConfig/initialChartConfig';
import { buildObjectFromCustomColumnObj } from 'src/components/pages/chartBuilder/createQueryComponents/dataSetupContainerPart/customColumnsDrawer';
import { DATAMART_TYPES } from 'src/constants';
import { dashboardFilterTypes } from 'src/constants';
const lodashUniq = require('lodash/uniq');
const lodashKeyBy = require('lodash/keyBy');
const lodashPick = require('lodash/pick');
const lodashKeys = require('lodash/keys');
const lodashAssign = require('lodash/assign');
const deepdash = require('deepdash/standalone');

export const elementOuterHeight = (el: HTMLElement) => {
  let height = el.offsetHeight;
  const style = getComputedStyle(el);
  height += parseInt(style.marginTop || '0', 10) + parseInt(style.marginBottom || '0', 10);
  return height;
};

export const defaultSorter = (a, b) => a - b;

export const getAuthenticationHeaders = () => {
  const credential = localStorage.getItem('sharedCredential');
  const parsedCredential = credential ? JSON.parse(credential) : {};
  const excludedKeys = ['parent-url', 'metadata'];
  for (const key of excludedKeys) {
    if (parsedCredential.hasOwnProperty(key)) {
      delete parsedCredential[key];
    }
  }
  return { ...parsedCredential, 'page-source':  localStorage.getItem('pageSource')};
};

export const getErrorMessage = (err) => {
  let errorMessage = '';
  if (!err.response) {
    errorMessage = 'Could not connect. Please check your internet connection';
  } else {
    const responseData = err.response.data;
    if (responseData && responseData.error) {
      errorMessage = responseData.error.message;
    } else {
      errorMessage = `Something went wrong\n${err.message}`;
    }
  }
  return errorMessage;
};

export const getErrorMessageForBlob = async (err) => {
  let errorMessage = '';
  if (!err.response) {
    errorMessage = 'Could not connect. Please check your internet connection';
  } else {
    const responseData = await new Response(err.response.data).json();
    if (responseData && responseData.error) {
      errorMessage = responseData.error.message;
    } else {
      errorMessage = `Something went wrong\n${err.message}`;
    }
  }
  return errorMessage;
};

export const messageType = {
  LOADED: 'LOADED',
  ROUTING: 'ROUTING',
  CREDENTIALS_SHARING: 'CREDENTIALS_SHARING',
};

export const applicationLoaded = () => {
  const message = JSON.stringify({
      type: messageType.LOADED,
      applicationId: 'GENERIC_ANALYTICS',
  });
  window.parent.postMessage(message, '*');
};

export const onMessageRecieved = (event, history, dispatch) => {
  const { data } = event;
  const message = typeof data === 'string' && data.length > 0 ? JSON.parse(data): data;
  switch(message.type){
    case messageType.ROUTING:
      const route = message.data;
      const params = new URLSearchParams(route.split('?')[1]);
      const queryParams = {};
      for (const [key, value] of params.entries()) {
        queryParams[key] = value;
      }
      if (Object.keys(queryParams).length > 0) {
        dispatch({ type: SET_EXTERNAL_META_DATA, payload: queryParams });
      }
      history.replace(route);
      break;
    case messageType.CREDENTIALS_SHARING:
      localStorage.setItem('sharedCredential', JSON.stringify(message.data));
      dispatch({ type: CHANGE_APPLICATION_NAME, payload: message['project-name']});
      dispatch({ type: RECIEVED_PARENT_CREDENTIAL });
      break;
    default:
  }
};

export const getNamesOfFilter = (filter) => {
  const keys: Array<string> = [];
  switch (filter.type) {
    case 'dateFilter':
    case 'multiSelect':
    case 'selectFilter':
    case 'multiInputFilter':
      keys.push(filter.name);
      break;
    case 'hubAndRider':
      keys.push(filter.hubName);
      keys.push(filter.riderName);
      break;
    default:
      keys.push(`Filter Type ${filter.type} not Found`);
      break;
  }
  return keys;
};

export const getValue = (value, filterType) => {
  switch (filterType) {
    case 'createdAtFilter':
    case 'consignmentCreatedAtFilter':
      return `From: ${value.from}      To: ${value.to}`;
    case 'isComplete':
    case 'isCOD':
    case 'statusFilter':
      return value;
    case 'consignmentType':
    case 'eventType':
    case 'currentHubFilter':
    case 'currentRiderFilter':
    case 'eventHubFilter':
    case 'eventRiderFilter':
    case 'zoneFilter':
      return value
        .map((s) => {
          if (typeof s !== 'string') return '';
          return s.charAt(0).toUpperCase() + s.slice(1);
        })
        .join(', ');
    default:
      return `Filter Type ${filterType} not Found`;
  }
};

export const changeKeyFromMetricToValue = (arr) => {
  return arr.map((element) => {
    const obj = {
      ...element,
      value: element.metric,
    };
    return lodashOmit(obj, 'metric');
  });
};

export const getSelectedNavKey = (path) => {
  let key;
  if(path === '/reports'){
    key = ['reports', 'reports_all'];
  } else if (path === '/dashboard'){
    key = ['dashboards', 'dashboards_all'];
  }else{
    key = ['home'];
  }
  return key;
};

const getReportTextTemplate = (report) => {
  const { reportId, reportName, _id , name } = report;
  return {
      id: reportId || _id,
      name: reportName || name,
      url: 'report url',
  };
};

const getReportMjmlTemplate = (report) => {
  let reportData = {};
  const { reportId, reportName, chartType, _id, name} = report;
  if (chartType === 'number') {
      reportData = {
          data: [
              { title: 'title1', value: 'value' },
              { title: 'title2', value: 'value' },
          ],
      };
  } else if (chartType === 'table') {
      reportData = {
          rows: [
              ['a1', 'b1', 'c1'],
              ['a2', 'b2', 'c2'],
          ],
          columns: ['A', 'B', 'C'],
      };
  } else {
      return {};
  }
  return {
      id: reportId || _id,
      name: reportName || name,
      ...reportData,
  };
};

const templateGeneratorMap = {
  mjml: getReportMjmlTemplate,
  text: getReportTextTemplate,
};

export const getReportTemplateMetaData = (reportData, mailType) => {
  const reportTemplateGenerator = templateGeneratorMap[mailType];
  const reportMetaData = reportTemplateGenerator(reportData);
  return reportMetaData;
};

export  const getDGTemplateMetaData = (dashbaordGroupData, mailType) => {
  const templateMetaData = {
      name: dashbaordGroupData.name,
      id: dashbaordGroupData.id,
  };
  dashbaordGroupData.dashboards.forEach((dashboard, i) => {
      const { name, _id } = dashboard.dashboardVisualisations.dashboard;
      const reportsData = {};
      dashboard.dashboardVisualisations.reports.forEach((report, j) => {
          const reportTemplateGenerator = templateGeneratorMap[mailType];
          const reportMetaData = reportTemplateGenerator(report);
          if (!lodashIsEmpty(reportMetaData)) {
              reportsData[`report_${j}`] = reportMetaData;
          }
      });
      if (!lodashIsEmpty(reportsData)) {
          templateMetaData[`dashboard_${i}`] = { id: _id, name, ...reportsData };
      }
  });
  return templateMetaData;
};

export  const showcaseTemplateData = (templateMetaData) => {
  return deepdash.omitDeep(templateMetaData, /(id)/gm, { onMatch: { skipChildren: false } });
};

export const saveTemplateData = (templateMetaData) => {
  return deepdash.pickDeep(templateMetaData, ['id']);
};

export const getProjectName = () => {
  const sharedCredential = localStorage.getItem('sharedCredential');
  const credential = JSON.parse(sharedCredential || '{}');
  return credential['project-name'];
};

export const getNavBarTheme = () => {
  const sharedCredential = localStorage.getItem('sharedCredential');
  const credential = JSON.parse(sharedCredential || '{}');
  return credential['metadata']?.['uiTheme'] ?? {};
};

export const getFilterColumns = (filters, reportType, reports) => {
  const newList = filters
    .map((filter) => {
      const reportMetrics = reports ? reports[reportType]?.metrics : [];
      const metrics = reportMetrics?.find(
        (metric) => metric.value === filter.metric,
      );
      if (metrics) {
        return { ...metrics, reportType };
      }
      const customFilter = buildObjectFromCustomColumnObj(
        {
          ...filter,
          type: filter.filter.type,
          name: filter.metric,
          prettyName: filter.filter.prettyName,
        },
        'global',
      );
      return customFilter;
    })
    .filter((element) => element !== undefined);
  return newList;
};
export const getParentWindowBaseURL = () => {
  const sharedCredential = localStorage.getItem('sharedCredential');
  const credential = JSON.parse(sharedCredential || '{}');
  const currentUrl = credential['parent-url'];
  const [baseUrl] = currentUrl.split('/analytics');
  return baseUrl;
};

export const getSharedCredential = () => {
  return JSON.parse(localStorage.getItem('sharedCredential') || '{}');
};

export const logoutUser = () => {
  localStorage.removeItem('sharedCredential');
  const parentUrl = getSharedCredential()['parent-url'];
  const hostname = new URL(parentUrl);
  if (window.top) {
    window.top.location.href = `${hostname}/login`;
  }
};

export const GetChartConfigData = (configObject) => {
  const { chartConfig, configType, formData, chartsFromDashboard } =
    configObject;
  const chartConfigData = useSelector(
    (state: any) => state.chartsMetaData.chartConfig,
  );
  const chartType = useSelector((state: any) => state.chartsMetaData.chartType);
  if (chartsFromDashboard) {
    //dashboard
    if (
      chartConfig &&
      (configType in chartConfig ||
        ['table', 'pivotTable'].includes(configType))
    ) {
      return chartConfig;
    }
    return initialChartConfig(configType, formData);
  }
  if (
    chartType === configType &&
    chartConfigData &&
    (configType in chartConfigData ||
      ['table', 'pivotTable'].includes(configType))
  ) {
    return chartConfigData;
  }
  return initialChartConfig(configType, formData);
};

export const getColor = (configColor, defaultColor) => {
  const data = configColor || defaultColor;
  const arrayColorObj = {
    columnHeaderFont: data[1],
    alternateRowsColor: data[3], //Header background, data grid background (zebra pattern)
    rowHeaderAndGridInteraction: '#D3D3D3', //Row header & data grid interaction (hover, selection, cross)
    columnHeaderBackground: data[0],
    headerInteraction: '#D3D3D3',
    brushMark: '#D3D3D3',
    outfitLink: '#D3D3D3',
    miniBar: '#D3D3D3', //Mini bar, resize interaction (reference lines, etc.)
    rowsBackground: data[2], //Data grid background (non-zebra pattern), overall table background color (recommended white)
    rowsFontColor: data[6], //Row header border, data grid border
    columnsHeaderBorderColor: data[5],
    verticalDividingLine: '#000000',
    horizontalDividingLine: '#000000',
    cellBorderColor: data[4],
    dataGridInteractiveColor: '#000000',
  };
  return Object.values(arrayColorObj);
};

export const getFormattedTime = (timestamp) => {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const amOrPm = hours < 12 ? 'AM' : 'PM';
  const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour time
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${formattedHours}:${formattedMinutes} ${amOrPm}`;
};

export const isManualQueryReport = (dataMartType) => {
  return dataMartType === DATAMART_TYPES.MANUAL_QUERY;
};

export const extractUniqueNamesInTripleCurlyBraces = (inputString) => {
  const regex = /\{\{\{(.*?)\}\}\}/g;
  const matches: any = [];

  let match;
  while ((match = regex.exec(inputString)) !== null) {
    matches.push(match[1]);
  }
  return lodashUniq(matches);
};

export const extractUniqueNamesInSquareBrackets = (inputString) => {
  const regex = /\[\[(.*?)\]\]/g;
  const matches: any = [];

  let match: any;
  while ((match = regex.exec(inputString)) !== null) {
    matches.push(match[1]);
  }
  const stringsInSquareBrackets: any = [];
  while (matches && matches.length) {
    const matchList: any = extractUniqueNamesInTripleCurlyBraces(
      matches[matches.length - 1],
    );
    stringsInSquareBrackets.push(matchList[0]);
    matches.pop();
  }
  return lodashUniq(stringsInSquareBrackets);
};

export const camelCaseToTitleCase = (inputString) => {
  // Replace each uppercase letter with a space followed by the same letter in lowercase
  const titleCaseString = inputString.replace(/([A-Z])/g, ' $1');

  // Capitalize the first letter and remove leading space (if any)
  return (
    titleCaseString.charAt(0).toUpperCase() + titleCaseString.slice(1).trim()
  );
};

export const getFiltersObjects = (
  mandatoryFilters,
  optionalFilters,
  filtersMetaData,
) => {
  const filters: any = [];
  mandatoryFilters.forEach((metric) => {
    let dashboardFilterType = dashboardFilterTypes.SIMPLE;
    let isDefaultFilter = false;
    if (['organisationId', 'hubId'].includes(metric)) {
      dashboardFilterType = dashboardFilterTypes.FROZEN;
      isDefaultFilter = true;
    }
    filters.push({
      value: metric,
      dashboardFilterType,
      prettyName: camelCaseToTitleCase(metric),
      type: 'text',
      selectedFilter: filtersMetaData['text'][0].name,
      isDefaultFilter,
    });
  });
  optionalFilters.forEach((metric) => {
    let dashboardFilterType = dashboardFilterTypes.SIMPLE;
    let isDefaultFilter = false;
    if (['organisationId', 'hubId'].includes(metric)) {
      dashboardFilterType = dashboardFilterTypes.FROZEN;
      isDefaultFilter = true;
    }
    filters.push({
      value: metric,
      dashboardFilterType,
      prettyName: metric,
      type: 'text',
      selectedFilter: filtersMetaData['text'][0].name,
      isDefaultFilter,
    });
  });
  return lodashKeyBy(filters, 'value');
};

export const getFilterObjectsWithPrevState = (
  newFilterObjects,
  previousFilterObjects = {},
) => {
  const updatedKeysPrevFilterObj = lodashPick(
    previousFilterObjects,
    lodashKeys(newFilterObjects),
  );
  const updatedNewFiltersObj = lodashAssign(
    {},
    newFilterObjects,
    updatedKeysPrevFilterObj,
  );
  return updatedNewFiltersObj;
};

export const isValidJSON = (jsonString) => {
  try {
      JSON.parse(jsonString);
  } catch (e) {
      return false;
  }
  return true;
};

export const generateMetricArray = (metricArray) => {
  const metricObjectArray: any = [];
  metricArray.forEach(({ type, operation, groupBy, metric, prettyName }) => {
    metricObjectArray.push({
      type,
      operation,
      groupBy,
      value: metric,
      prettyName,
    });
  });
  return metricObjectArray;
};
