import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Chart } from '@antv/g2';
import initialChartConfig from 'src/components/pages/chartBuilder/createQueryComponents/chartConfig/initialChartConfig';

const ProgressBarViz = (props) => {
  const {
    formData,
    chartData,
    chartConfig,
    visualizeMetaData,
    chartInteractionHook,
    showUnderlyingDataDownload,
    chartsFromDashboard,
    reportId,
  } = props;

  const enableDummyDataSwitch = useSelector(
    (state: any) => state.reportMetaData.dummyDataSwitch,
  );
  const chartConfigData = useSelector(
    (state: any) => state.chartsMetaData.chartConfig,
  );

  const configType = 'progressBar';
  let initialChartConfigData = chartConfigData;

  if (chartsFromDashboard && chartConfig && configType in chartConfig) {
    initialChartConfigData = chartConfig;
  } else {
    initialChartConfigData = initialChartConfig(configType, formData);
  }
  let {
    graphic = {},
    graphicLabel = {},
    legend = {},
    progressBar,
    xAxis = {},
    yAxis = {},
  } = chartConfigData;
  const { xTitle = {}, yTitle = {}, columnSize = {} } = chartConfigData;
  if (!progressBar) {
    graphic = initialChartConfigData?.graphic;
    graphicLabel = initialChartConfigData?.graphicLabel;
    legend = initialChartConfigData?.legend;
    progressBar = initialChartConfigData?.progressBar;
    xAxis = initialChartConfigData?.xAxis;
    yAxis = initialChartConfigData?.yAxis;
  }
  const columnWidth =
    graphic?.columnSize?.data === 'default'
      ? 60
      : columnSize?.thicknessValue?.data * 10;
  const xAxisLabel =
    progressBar?.xAxis?.data && xAxis?.xTitle?.data ? xTitle?.text?.data : null;
  const yAxisLabel =
    progressBar?.yAxis?.data && yAxis?.yTitle?.data ? yTitle?.text?.data : null;

  useEffect(() => {
    const chart = new Chart({
      container: reportId || 'container',
      height: 150,
      width: columnWidth * 10 || 600,
    });

    chart.on('element:click', async (...args) => {
      const columnData = args[0]?.data?.data;
      const { x, y } = args[0]?.global;
      chartInteractionHook.setPopOverPosition({ x, y });
      const showChildReport = visualizeMetaData?.columnsData[columnData.name]
        ?.linkedReport?.reportId
        ? true
        : false;
      await chartInteractionHook.onChartColumnClick({
        showChildReport,
        showUnderlyingDataDownload,
        reportData: { visualizeMetaData, columnData },
        reportId,
        enableDummyDataSwitch,
      });
    });
    chart.coordinate({ transform: [{ type: 'transpose' }] });
    const totalYCount = chartData.reduce((total, item) => total + item.y, 0);
    chart
      .interval()
      .data(chartData)
      .transform({ type: 'groupColor', y: 'sum' })
      .transform({ type: 'stackY' })
      .transform({ type: 'normalizeY' })
      .axis('y', {
        labelFormatter: '.0%',
        title: xAxisLabel || '',
      })
      .scale('color', { type: 'ordinal', range: graphic?.color?.data })
      .encode('color', 'x')
      .encode('y', 'y')
      .label(
        progressBar?.graphicLabel?.data
          ? {
              text: 'x',
              dy: graphicLabel?.offsetY?.data,
              dx: graphicLabel?.offsetX?.data,
              position:
                graphicLabel?.position?.data === 'middle'
                  ? 'inside'
                  : graphicLabel?.position?.data,
              style: { fontSize: graphicLabel?.labelSize?.data },
            }
          : { text: '' },
      )
      .tooltip(
        graphic?.tooltip?.data
          ? {
            items: [
              (item) => {
                const percentage = ((item.y / totalYCount) * 100).toFixed(2);
                return {
                  name: `${
                    yAxisLabel ||
                    formData?.y_axis[0]?.metric?.prettyName ||
                    'Y'
                  }`,
                  value: `${percentage}%`,
                };
              },
            ],
          }
          : false,
      );

    const legendPosition = {
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    };

    if (legend) {
      switch (legend.location?.data) {
        case 'top-right':
          legendPosition.alignItems = 'flex-end';
          break;
        case 'top-left':
          legendPosition.alignItems = 'flex-start';
          break;
      }
    }

    progressBar?.legend?.data
      ? chart.legend('color', {
          layout: legendPosition,
          maxRows: 1,
          colPadding: 10,
        })
      : chart.legend(false);

    chart.render();
  }, [chartConfigData, chartData]);
  const id = reportId || 'container';
  return <div id={id}></div>;
};

const areEqual = (prevProps, nextProps) => {
  // additional props in parent like classes and chartOptions
  // were causing a re-render
  if (
    prevProps.formData === nextProps.formData &&
    prevProps.chartData === nextProps.chartData
  ) {
    return true; // do not re-render
  }
  return false; // will re-render
};

export default memo(ProgressBarViz, areEqual);
