export const transformFilters = (filters) => filters.map(filter => {
    const newFilter: Record<string, any> = {
        metric: filter.metric || filter.value,
        filter: {
            name: filter.selectedFilter,
            prettyName: filter.prettyName,
            type: filter.type,
            isDefaultFilter: filter.isDefaultFilter || false,
            sql: filter.sql || filter?.filter?.sql || '',
        },
        isCustomReportFilter: filter.isCustomReportFilter,
        section: filter.section,
        caseInsensitive: filter.caseInsensitive || false,
        dashboardFilterType: filter.dashboardFilterType,
    };
    if(filter.data) {
        newFilter.filter.data = JSON.stringify(filter.data);
    }
    return newFilter;
});

export const transformHavingFilters = (filters) => filters.map(filter => {
    const newFilter: Record<string, any> = {
        metric: filter.value,
        filter: {
            name: filter.selectedFilter,
            prettyName: filter.prettyName,
            type: filter.type,
            havingOperation: filter.havingOperation,
        },
    };
    if(filter.data) {
        newFilter.filter.data = JSON.stringify(filter.data);
    }
    return newFilter;
});

export const getfilterWithUnSelectedValues = (filters, supportedFilters) => filters.filter(filter => {
    const name = filter.selectedFilter;
    const data = filter.data;
    if(!name){
        return true;
    }
    const subFilterList = supportedFilters[filter.type] || [];
    const selectedFilter = subFilterList.find(subFilter => subFilter.name === name) || {};
    const filterType = selectedFilter.type;
    if(filterType === 'noInput'){
        return false;
    }
    if(!data){
        return true;
    }
    return false;
}).map(filter => filter.metric || filter.value);

export const gethavingFiltersWithUnSelectedValues = (havingFilters, supportedFilters) => havingFilters.filter(filter => {
    const name = filter.selectedFilter;
    const {havingOperation, data, havingOperations} = filter;
    if(!name){
        return true;
    }
    const subFilterList = supportedFilters[filter.type] || [];
    const selectedFilter = subFilterList.find(subFilter => subFilter.name === name) || {};
    const filterType = selectedFilter.type;
    if(filterType === 'noInput'){
        return false;
    }
    if(!data || (havingOperations && !havingOperation)){
        return true;
    }
    return false;
}).map(filter => filter.prettyName);

export const getSelectedFilters = (filters, supportedFilters) => {
    const filterWithUnSelectedValues = getfilterWithUnSelectedValues(filters, supportedFilters);
    return filters.filter(filter => !filterWithUnSelectedValues.includes(filter.prettyName));
};

export const convertBackendFilter = (data, metrices) => data.map((filter) => {
    const metric = metrices.find((metric) => metric.value === filter.metric);
    return {
        ...metric,
        selectedFilter: filter.filter.name,
        data: JSON.parse(filter.filter.data),
    };
});

export const checkAllValuesNotNull = (object) => {

    if(object == null){
        return false;
    }
    for (const value of Object.values(object)) {
        if (!value) {
        return false;
        }
    }
    return true;
};
export const transformFilterWithSelectedValue = (element) => {
    //4 cases
    if ('data' in element) {
        if (element.data) {
        if (element.data?.value?.length === undefined) {
            return checkAllValuesNotNull(element.data.value);//when data is of objects type
        }
        return element.data?.value.length;// //when data is of string or array type
        }
    }
    return true;//when there is no data
};
