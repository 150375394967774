import { gql } from '@apollo/client';

export const GET_METRIC_FIELDS_BY_OBJECT = gql`
  query getMetricFieldsByObject($objectName: String!) {
    getMetricFieldsByObject(objectName: $objectName) {
      id
      name
      description
      reportType
      field {
        metric
        prettyName
      }
    }
  }
`;

export const GET_INCIDENT_ALERT_BY_NAME = gql`
  query getIncidentAlertByName(
    $searchQuery: String!
    $sortBy: sortObjectInput
    $offSet: Int
    $isPagination: Boolean
    $filters: [reportFilterInput]
  ) {
    getIncidentAlertByName(
      searchQuery: $searchQuery
      sortBy: $sortBy
      offSet: $offSet
      isPagination: $isPagination
      filters: $filters
    ) {
      alerts {
        id
        name
        creationTime
        updationTime
        numAlertsCreated
        numLiveAlerts
        createdByName
        status
      }
      count
    }
  }
`;

export const GET_INCIDENT_ALERT_BY_ID = gql`
  query getIncidentAlertById($id: String!) {
    getIncidentAlertById(id: $id) {
      id
      name
      metricId
      metricName
      metricDescription
      metricReportType
      metricObject {
        name
        field {
          metric
          prettyName
        }
      }
      filters {
        metric
        section
        filter {
          name
          prettyName
          data
          type
        }
        caseInsensitive
        dashboardFilterType
      }
      criterias {
        isAdvance
        criterias {
          operator
          threshold
          lhs
          rhsSelector
          rhs
          coefficient
          aggregate
          historization
          historizationValue
        }
      }
      incidentGeneration {
        limit
        prioritisation
      }
      scheduler {
        frequency
        frequencyData {
          dateMonth
          date
          dayOfWeek
        }
        startTimestamp
        endTimestamp
        repeat
        intervalType
        fromToRange {
          timeRange
        }
        customInterval
      }
      assignee {
        name
        email
        empId
      }
      suppressSubsequentAlerts
      emailNotification
      resolutionSLA {
        quantity
        timeframe
        autoClose
      }
      ticketDetails {
        incidentType
        priority
      }
      linkedDashboardGroups
    }
  }
`;

export const DELETE_INCIDENT_ALERT = gql`
  query deleteIncidentAlert($ids: [String]!) {
    deleteIncidentAlert(ids: $ids) {
      success
      error
    }
  }
`;

export const GET_INCIDENT_ALERTS_BY_METRIC_ID = gql`
  query getIncidentAlertsByMetricId($metricId: String!) {
    getIncidentAlertsByMetricId(metricId: $metricId) {
      id
      name
      creationTime
      updationTime
      numAlertsCreated
      numLiveAlerts
      createdByName
      status
    }
  }
`;

export const DISABLE_INCIDENT_ALERT_STATUS = gql`
  query disableIncidentAlertsStatus($ids: [String]!, $status: String!) {
    disableIncidentAlertsStatus(ids: $ids, status: $status) {
      success
      error
    }
  }
`;
