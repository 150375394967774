export const rhsSelectorOptions = [
  {
    value: 'threshold',
    label: 'Threshold',
  },
  {
    value: 'value',
    label: 'Value',
  },
  {
    value: 'coefficient_and_aggregate',
    label: 'Coefficient and Aggregate',
  },
];

export const methodologyOptions = [
  {
    value: 'average',
    label: 'Average',
  },
  {
    value: 'sum',
    label: 'Sum',
  },
  {
    value: 'median',
    label: 'Median',
  },
  {
    value: 'percentile',
    label: 'Percentile',
  },
];

export const prioritisationOptions = [
  {
    value: 'max_deviation',
    label: 'Maximum Deviation',
  },
  {
    value: 'default_sort',
    label: 'Default Sort',
  },
];

export const operatorOptions = [
  {
    value: '=',
    label: '=',
  },
  {
    value: '>',
    label: '>',
  },
  {
    value: '<',
    label: '<',
  },
  {
    value: '>=',
    label: '>=',
  },
  {
    value: '=<',
    label: '=<',
  },
];

export const priorityOptions = [
  {
    label: 'Low',
    value: 'low',
  },
  {
    label: 'Medium',
    value: 'medium',
  },
  {
    label: 'High',
    value: 'high',
  },
];

export const productivityOptions = [
  {
    label: 'Attendance',
    value: 'attendance',
  },
  {
    label: 'Productivity',
    value: 'productivity',
  },
  {
    label: 'TAT',
    value: 'tat',
  },
  {
    label: 'Finance',
    value: 'finance',
  },
];

export const timeFrameOptions = [
  {
    label: 'Minutes',
    value: 'minutes',
  },
  {
    label: 'Hours',
    value: 'hours',
  },
  {
    label: 'Days',
    value: 'days',
  },
];

export const defaultEmpDetails = [
  {
    label: 'Manager',
    value: JSON.stringify({ name: 'Manager', empId: 'manager' }),
  },
];

export const defaultAlertColumns = [
  {
    title: 'Object ID',
    dataIndex: 'objectId',
    key: 'objectId',
  },
  {
    title: 'Mapped Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Mapped Email ID',
    dataIndex: 'emailId',
    key: 'emailId',
  },
  {
    title: 'Manager Name',
    dataIndex: 'managerName',
    key: 'managerName',
  },
  {
    title: 'Manager Email ID',
    dataIndex: 'managerEmailId',
    key: 'managerEmailId',
  },
];

export const incidentTypeOptions = [
  { value: 'LIVE', label: 'LIVE' },
  { value: 'EVENT', label: 'EVENT' },
];

export const metricObjectTypeOptions = ['Hub', 'Rider', 'Vehicle'];
