import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import lodashUniqBy from 'lodash/uniqBy';
import lodashValues from 'lodash/values';
import lodashUnion from 'lodash/union';
import lodashPick from 'lodash/pick';
import lodashCompact from 'lodash/compact';
import lodashMapKeys from 'lodash/mapKeys';
import { message } from 'antd';

import {
  METRICS_CHANGED,
  CHANGE_METRIC_ALIAS,
  CHANGE_AGGREGATE,
  SUMMARISATION_LIST_CHANGED,
  CHART_OPTION_VALUE_CHANGED,
  RESET_ALL_AGGREGATE,
  FILTER_DATA_CHANGED,
  SORT_SELECTED_LIST_CHANGED,
  SORT_VALUE_CHANGED,
  SET_IS_AGGREGATED,
  HAVING_FILTER_DATA_CHANGED,
  HAVING_INITIALIZE_FILTERS,
  ADD_METRIC_LINK_REPORT,
  REMOVE_METRIC_LINK_REPORT,
  GENERIC_FILTER_DATA_CHANGED,
  GENERIC_RESET_ALL_AGGREGATE,
  GENERIC_SUMMARISATION_LIST_CHANGED,
  REMOVE_LINKED_METRIC,
  REMOVE_ALL_LINKED_REPORTS,
  UNDERLYING_METRICS_CHANGED,
  CHANGE_UNDERLYING_METRIC_ALIAS,
  FORMATTING_DELETE_METRIC,
  REMOVE_METRIC_KEY,
  CHANGE_METRIC_STRING_MANUAL_QUERY,
} from 'src/reduxActions/actionNameEnums';
import { usePrevious } from 'src/components/common/hooks/usePreviousRef';
import { getDownloadRowLimitWarningMessage } from '../chartBuilderUtils';

const removeLinkedMetric = (parentMetricsForChildReport, linkedMetricRemoved, dispatch) => {
  if (parentMetricsForChildReport.findIndex((metric) => metric === linkedMetricRemoved) < 0) {
    dispatch({
      type: REMOVE_LINKED_METRIC,
      payload: { linkedMetricRemoved },
    });
  }
};

export const useCustomMetrices = (filteredColumns) => {
  const dispatch = useDispatch();
  const reorderCustomMetricString = (newItems) => {
    dispatch({
      type: CHANGE_METRIC_STRING_MANUAL_QUERY,
      payload: {
        metricString: JSON.stringify(newItems, null, 2),
      },
    });
  };

  const onAddLinkedReport = (name, reportId, reportName, linkedMetrices) => {
    const updatedFilteredColumns = filteredColumns.map((col) => {
      if (col.metric === name) {
        col.linkedReport = {
          reportId,
          reportName,
          linkedMetrices,
        };
      }
      return col;
    });
    dispatch({
      type: CHANGE_METRIC_STRING_MANUAL_QUERY,
      payload: {
        metricString: JSON.stringify(updatedFilteredColumns, null, 2),
      },
    });
  };

  const onRemoveLinkedReport = (name) => {
    const updatedFilteredColumns = filteredColumns.map((col) => {
      if (col.metric === name) {
        const { linkedReport, ...restCol } = col;
        return restCol;
      }
      return col;
    });
    dispatch({
      type: CHANGE_METRIC_STRING_MANUAL_QUERY,
      payload: {
        metricString: JSON.stringify(updatedFilteredColumns, null, 2),
      },
    });
  };

  return {
    reorderCustomMetricString,
    onAddLinkedReport,
    onRemoveLinkedReport,
  };
};

export const useMetrices = (metrics) => {
  const [selectableMetrics, setSelectableMetrics] = useState(metrics);
  const selectedMetricsAsObject = useSelector((state: any) => state.metrics);
  const formatting = useSelector((state: any) => state.chartsMetaData.formatting);
  const summarizationObject = useSelector((state: any) => state.summarization) || {};
  const genericSelectedAggregateObject = useSelector((state: any) => state.genericSummarisation) || {};
  const downloadRowLimitConfig = useSelector((state:any) => state?.masterData?.organisationConfig?.downloadRowLimitConfig);
  const enableRedshiftDump = useSelector((state:any) => state?.masterData?.organisationConfig?.enableRedshiftDump);
  const selectedReportType = useSelector((state: any) => state.reportMetaData.selectedReport);
  const selectedDbType = useSelector((state: any) => state.reportConfig?.reports[selectedReportType]?.dbType);
  const maxElasticSearchRowsToDownload = useSelector((state: any) => state.masterData.organisationConfig?.maxElasticSearchRowsToDownload);
  const selectedMetrics = lodashValues(selectedMetricsAsObject);

  const dispatch = useDispatch();
  useEffect(() => setSelectableMetrics(metrics), [metrics]);

  const reorderMetrices = (newMetricList) => {
    const newList = newMetricList.map((item) => item.value);
    onSelect(newList);
  };
  const removeLocalMatrices = () => {
    const newList = selectedMetrics
      .filter(
        (item) => !(item.customColumnType && item.customColumnType === 'local'),
      )
      .map((item) => item.value);
    onSelect(newList);
  };
  const onRemoveMetrics = (metricsName) => {
    const newList = selectedMetrics
      .filter((filter) => filter.value !== metricsName)
      .map((filter) => filter.value);
    onSelect(newList);

    if(formatting?.length) {
      const ruleMetricIndex = formatting.findIndex((rule) => rule.metric === metricsName);
      if(ruleMetricIndex > -1) {
        const rulesCopy = [...formatting];
        rulesCopy.splice(ruleMetricIndex, 1);
        dispatch({
          type: FORMATTING_DELETE_METRIC,
          payload: rulesCopy,
        });
      }
    }
  };

  const onAddLinkedReport = (name, reportId, reportName, linkedMetrices) => {
    dispatch({
      type: ADD_METRIC_LINK_REPORT,
      payload: { name, reportId, reportName, linkedMetrices },
    });
  };

  const onRemoveLinkedReport = (name) => {
    dispatch({
      type: REMOVE_METRIC_LINK_REPORT,
      payload: { name },
    });
  };

  const onSelect = (values, options = { showErrorOnDuplicate: false }) => {
    const { showErrorOnDuplicate } = options;

    if (showErrorOnDuplicate) {
      let toRet = false;
      values.map((value) => {
        if (genericSelectedAggregateObject['summariseBy']?.[value]) {
          message.error(`Column ${value} already present in Summarise By`);
          toRet = true;
        }
        if (genericSelectedAggregateObject['timeline']?.[value]) {
          message.error(`Column ${value} already present in Timeline`);
          toRet = true;
        }
        if (selectedMetricsAsObject[value]) {
          message.error(`Column ${value} already present`);
          toRet = true;
        }
      });
      if (toRet) return;
    }
    //this is running in complexity of O(n2) but we are bearing this as it is tradeoff complexity and value of n is low also!!
    const metricData = values.map(
      (value) =>
        selectedMetricsAsObject[value] ||
        metrics.find((metric) => metric.value === value),
    );

    const previousColumnsLength = Object.keys({ ...selectedMetricsAsObject, ...summarizationObject }).length;
    const metricsDataObject = lodashMapKeys(metricData, (data, keys) => data.value);
    const currentColumnsLength = Object.keys({ ...metricsDataObject, ...summarizationObject }).length;
    const downloadRowLimitWarningMessage: string | undefined = getDownloadRowLimitWarningMessage({
      downloadRowLimitConfig,
      previousColumnsLength,
      currentColumnsLength,
      enableRedshiftDump,
      selectedDbType,
      maxElasticSearchRowsToDownload,
    });

    if (downloadRowLimitWarningMessage) {
        message.warning(downloadRowLimitWarningMessage);
    }

    dispatch({
      type: METRICS_CHANGED,
      payload: metricData,
    });
  };

  const onChangeAlias = (name, value) => {
    dispatch({
      type: CHANGE_METRIC_ALIAS,
      payload: { value, name },
    });
  };

  const onChangeAggregate = (name, value) => {
    dispatch({
      type: CHANGE_AGGREGATE,
      payload: { name, value },
    });
  };

  return {
    onRemoveMetrics,
    selectableMetrics,
    selectedMetrics,
    onSelect,
    onChangeAlias,
    onChangeAggregate,
    reorderMetrices,
    onRemoveLinkedReport,
    onAddLinkedReport,
    removeLocalMatrices,
  };
};

export const useFilters = (metrics, options = { metricsToSet: [] }) => {
  const filtersMetaData = useSelector(
    (state: any) => state.masterData?.supportedFilters,
  );
  const selectedSummarizationsObject = useSelector((state: any) => state.summarization);
  const selectedDataObject = useSelector((state: any) => state.filterData);
  const selectedData = lodashValues(selectedDataObject);
  const dispatch = useDispatch();
  const requiredFilterKeys = metrics
    .filter((metric) => metric?.filterMetaData?.required)
    .map((metric) => metric.value);

  const onRemoveFilter = (filterName) => {
    const newFilterMetricList = selectedData
      .filter((filter) => filter.value !== filterName)
      .map((filter) => filter.value);
    const newParentMetricsForChildReport = lodashUnion(
      newFilterMetricList, Object.keys(selectedSummarizationsObject),
    );
    onSelectFilterMetrics(newFilterMetricList);
    removeLinkedMetric(newParentMetricsForChildReport, filterName, dispatch);
  };

  const onSelectFilterMetrics = (values) => {
    const isrequiredFilterSelected = requiredFilterKeys.every((filter) =>
      values.includes(filter),
    );
    if (isrequiredFilterSelected) {
      const metricData = values.map((value) => {
        return (
          selectedDataObject[value] ||
          metrics.find((metric) => metric.value === value)
        );
      });
      dispatch({
        type: FILTER_DATA_CHANGED,
        payload: { metricData, filtersMetaData },
      });
    } else {
      message.warning('Cannot de-select the required filter');
    }
  };

  const allNonrequiredFilters = metrics.filter(
    (metric) => !metric?.filterMetaData?.required,
  );
  const requiredFilters = requiredFilterKeys.map((value) => {
    return (
      selectedDataObject[value] ||
      metrics.find((metric) => metric.value === value)
    );
  });

  let updatedMetrics = null;
  const metricsToBeUpdated = options.metricsToSet?.length;
  if (metricsToBeUpdated) {
    const { metricsToSet }: any = options;
    updatedMetrics = metrics.filter((metric) =>
      metricsToSet.some((metricToSet) => metricToSet.metric === metric.value),
    );
  }

  return {
    filtersOptions: metricsToBeUpdated ? updatedMetrics : metrics,
    filtersMetaData,
    onSelectFilterMetrics,
    selectedData,
    onRemoveFilter,
  };
};

export const useSortBy = (metrics) => {
  const dispatch = useDispatch();
  const sortMetaData = useSelector((state: any) => state.sortMetaData);
  const selectedSortBy = lodashValues(sortMetaData);
  const summarisation = useSelector((state: any) => state.summarization);
  const summarisationList = lodashValues(summarisation);
  const sortableMetricesWithRedundantMetric = selectedSortBy.concat(metrics);
  const sortableMetrics = lodashUniqBy(sortableMetricesWithRedundantMetric, metric => metric.value);
  const onRemoveSortBy = (sortByMetricName) => {
    const newList = selectedSortBy
      .filter((filter) => filter.value !== sortByMetricName)
      .map((filter) => filter.value);
    onSelectSortMetrics(newList);
  };

  const reorderSortMetrics = (result) => {
    const newMetricList = [...selectedSortBy];
    const [removed] = newMetricList.splice(result.source.index, 1);
    newMetricList.splice(result.destination.index, 0, removed);
    const newList = newMetricList.map((item) => item.value);
    onSelectSortMetrics(newList);
  };

  const onSelectSortMetrics = (values, sortMetrics = sortableMetrics) => {
    const availableMetrics = [...sortMetrics, ...sortableMetrics];
    const metricData = values.map((value) =>
      availableMetrics.find((metric) => metric.value === value),
    );
    dispatch({
      type: SORT_SELECTED_LIST_CHANGED,
      payload: metricData,
    });
  };

  const onChangeSortBy = (metricName) => (value) =>
    dispatch({
      type: SORT_VALUE_CHANGED,
      payload: { metricName, value },
    });

  return { onRemoveSortBy, selectedSortBy, sortableMetrics, onSelectSortMetrics, onChangeSortBy, reorderSortMetrics };
};

export const useAggregate = (metrics) => {
  const dispatch = useDispatch();
  const [aggregateOptions, setAggregateOptions] = useState([]);
  const selectedAggregateObject = useSelector(
    (state: any) => state.summarization,
  );
  const chartsMetaData = useSelector(
    (state: any) => state.chartsMetaData,
  );
  const {chartType, chartOptions} = chartsMetaData;
  const metricsMetaData = useMetrices(metrics);
  const selectedAggregate = lodashValues(selectedAggregateObject);
  const selectedFiltersObject = useSelector((state: any) => state.filterData);
  const filtersMetaData = useSelector(
    (state: any) => state?.masterData?.supportedFilters,
  );
  const metricsDataObject = useSelector((state:any) => state.metrics) || {};
  const downloadRowLimitConfig = useSelector((state:any) => state?.masterData?.organisationConfig?.downloadRowLimitConfig);
  const enableRedshiftDump = useSelector((state:any) => state?.masterData?.organisationConfig?.enableRedshiftDump);
  const selectedReportType = useSelector((state: any) => state.reportMetaData.selectedReport);
  const selectedDbType = useSelector((state: any) => state.reportConfig?.reports[selectedReportType]?.dbType);
  const maxElasticSearchRowsToDownload = useSelector((state: any) => state.masterData.organisationConfig?.maxElasticSearchRowsToDownload);

  useEffect(() => {
    const aggregatable = metrics.filter((metric) => metric.groupBy?.allowed);
    setAggregateOptions(aggregatable);
  }, [metrics]);

  const onRemoveSummarisation = (metricName, resetAggregate = true) => {
    const newSummariztionMetricList = selectedAggregate
      .filter((filter) => filter.value !== metricName)
      .map((filter) => filter.value);
    onChangeAggregateSelection(newSummariztionMetricList);
    const newParentMetricsForChildReport = lodashUnion(
      Object.keys(selectedFiltersObject), newSummariztionMetricList,
    );
    removeLinkedMetric(newParentMetricsForChildReport, metricName, dispatch);
    if (newSummariztionMetricList.length === 0) {
      metricsMetaData.removeLocalMatrices();
      if (resetAggregate) dispatch({ type: RESET_ALL_AGGREGATE });
      dispatch({
        type: HAVING_INITIALIZE_FILTERS,
        payload: { metricData: [], filtersMetaData },
      });
      dispatch({
        type: REMOVE_ALL_LINKED_REPORTS,
        payload: {},
      });
    }
  };

  const setRowColumn = (optionName, value) => {
    dispatch({
      type: CHART_OPTION_VALUE_CHANGED,
      payload: {
        optionName,
        value,
        section: 'rowColumns',
      },
    });
  };

  const isSummarisationPresent = (data,summarisationMetrics) => {
    let isChanged = false;
    data.map((row, index) => {
      const isPresent = summarisationMetrics.some(
        (item) => item.value === row.value,
      );
      if (!isPresent) {
        data.splice(index, 1);
        isChanged = true;
      }
    });
    return isChanged;
  };

  const changePivotTable = (metricData) => {
    const summarisationMetrics = metricData.map((item) => ({
      ...item,
      chartSortBy: 'ASC',
    }));
    let rows = chartOptions?.rowColumns?.row?.data || [];
    const columns = chartOptions?.rowColumns?.column?.data || [];
    let isRowsChanged = false;
    let isColumnChanged = false;
    const rowColumn = [...rows, ...columns];
    summarisationMetrics.map((val) => {
      const isPresent = rowColumn.some((item) => item.value === val.value);
      if (!isPresent) {
        rows = [...rows, val];
        isRowsChanged = true;
      }
    });
    isRowsChanged = isRowsChanged || isSummarisationPresent(rows,summarisationMetrics);
    isColumnChanged = isSummarisationPresent(columns,summarisationMetrics);
    if (isRowsChanged) {
      setRowColumn('row', rows);
    }
    if (isColumnChanged) {
      setRowColumn('column', columns);
    }
  };

  const onChangeAggregateSelection = (values) => {
    const metricData = values.map(
      (value) =>
        selectedAggregateObject[value] ||
        aggregateOptions.find((metric: any) => metric.value === value),
    );
    const summarizationObject = lodashMapKeys(metricData, (data, keys) => data.value);
    const previousColumnsLength = Object.keys({ ...metricsDataObject, ...selectedAggregateObject }).length;
    const currentColumnsLength = Object.keys({ ...metricsDataObject, ...summarizationObject }).length;
    const downloadRowLimitWarningMessage: string | undefined = getDownloadRowLimitWarningMessage({
      downloadRowLimitConfig,
      previousColumnsLength,
      currentColumnsLength,
      enableRedshiftDump,
      selectedDbType,
      maxElasticSearchRowsToDownload,
    });

    if (downloadRowLimitWarningMessage) {
        message.warning(downloadRowLimitWarningMessage);
    }

    dispatch({
      type: SUMMARISATION_LIST_CHANGED,
      payload: metricData,
    });
    dispatch({
      type: REMOVE_METRIC_KEY,
      payload: metricData,
    });
    if(chartType==='pivotTable'){
      changePivotTable(metricData);
    }
  };

  return {
    aggregateOptions,
    metricsMetaData,
    onChangeAggregateSelection,
    selectedAggregate,
    onRemoveSummarisation,
    selectedAggregateObject,
  };
};

export const useAggregateSwitch = (initialState) => {
  const checked = useSelector(
    (state: any) => state.reportMetaData.isAggregated,
  );
  const setChecked = (data) =>
    dispatch({ type: SET_IS_AGGREGATED, payload: data });
  const dispatch = useDispatch();
  const onChangeChecked = (value) => {
    setChecked(value);
    if (!value) {
      dispatch({ type: RESET_ALL_AGGREGATE });
    }
  };
  return [checked, onChangeChecked];
};

export const useHavingFilters = (metrics) => {
  const havingFiltersMetaData = useSelector(
    (state: any) => state.masterData?.supportedFilters,
  );
  const filtersMetaData = useSelector(
    (state: any) => state.masterData?.supportedFilters,
  );
  // Change Supported filters
  const selectedDataObject = useSelector(
    (state: any) => state.havingFilterData,
  );
  const selectedData = lodashValues(selectedDataObject);
  const dispatch = useDispatch();
  const requiredFilterKeys = metrics
    .filter((metric) => metric?.filterMetaData?.required)
    .map((metric) => metric.value);

  const onRemoveHavingFilter = (filterName) => {
    const newList = selectedData
      .filter((filter) => filter.value !== filterName)
      .map((filter) => filter.value);
    onSelectHavingFilterMetrics(newList);
  };

  const onSelectHavingFilterMetrics = (values) => {
    const metricData = values.map((value) => {
      return (
        selectedDataObject[value] ||
        metrics.find((metric) => metric.value === value)
      );
    });
    dispatch({
      type: HAVING_FILTER_DATA_CHANGED,
      payload: { metricData, filtersMetaData },
    });
  };

  return {
    havingFiltersOptions: metrics,
    havingFiltersMetaData,
    onSelectHavingFilterMetrics,
    selectedData,
    onRemoveHavingFilter,
  };
};

export const useGenericFilters = (metrics, type) => {
  const filtersMetaData = useSelector(
    (state: any) => state.masterData?.supportedFilters,
  );
  const selectedDataObject = useSelector(
    (state: any) => state.genericFilterData,
  );
  const selectedData = lodashValues(selectedDataObject[type]);
  const dispatch = useDispatch();
  const requiredFilterKeys = metrics
    .filter((metric) => metric?.filterMetaData?.required)
    .map((metric) => metric.value);

  const onRemoveFilter = (filterName) => {
    const newList = selectedData
      .filter((filter) => filter.value !== filterName)
      .map((filter) => filter.value);
    onSelectFilterMetrics(newList);
  };

  const onSelectFilterMetrics = (values) => {
    const metricData = values.map((value) => {
      return (
        selectedDataObject[type]?.[value] ||
        metrics.find((metric) => metric.value === value)
      );
    });
    dispatch({
      type: GENERIC_FILTER_DATA_CHANGED,
      payload: { metricData, filterType: type, filtersMetaData },
    });
  };

  const allNonrequiredFilters = metrics.filter(
    (metric) => !metric?.filterMetaData?.required,
  );
  const requiredFilters = requiredFilterKeys.map((value) => {
    return (
      selectedDataObject[value] ||
      metrics.find((metric) => metric.value === value)
    );
  });
  return {
    filtersOptions: metrics,
    filtersMetaData,
    onSelectFilterMetrics,
    selectedData,
    onRemoveFilter,
  };
};

export const useGenericAggregate = (metrics, type) => {
  const dispatch = useDispatch();
  const [aggregateOptions, setAggregateOptions] = useState([]);
  const selectedAggregateObject = useSelector(
    (state: any) => state.genericSummarisation,
  );
  const metricsMetaData = useMetrices(metrics);
  const selectedAggregate = lodashValues(selectedAggregateObject[type]);
  const filtersMetaData = useSelector(
    (state: any) => state?.masterData?.supportedFilters,
  );
  const sortBy = useSortBy(metricsMetaData.selectedMetrics);

  useEffect(() => {
    const aggregatable = metrics.filter((metric) => metric.groupBy?.allowed);
    setAggregateOptions(aggregatable);
  }, [metrics]);

  const onRemoveSummarisation = (
    metricName,
    options = {
      updateSortBy: false,
    },
  ) => {
    const newList = selectedAggregate
      .filter(
        (filter) => filter.value !== metricName && filter.section === type,
      )
      .map((filter) => filter.value);
    onChangeAggregateSelection(newList, options);
    if (newList.length === 0) {
      metricsMetaData.removeLocalMatrices();
      dispatch({ type: GENERIC_RESET_ALL_AGGREGATE });
      dispatch({
        type: HAVING_INITIALIZE_FILTERS,
        payload: { metricData: [], filtersMetaData },
      });
    }
  };

  const onChangeAggregateSelection = async (values, options = {
    updateSortBy: false,
  }) => {
    const metricData = values.map(value => selectedAggregateObject[type]?.[value] || aggregateOptions.find((metric:any) => metric.value === value));

    if (
      type === 'timeline' &&
      metricData.length &&
      !['timestampz', 'timestamp', 'date'].includes(metricData[0].type)
    ) {
      return;
    }

    dispatch({
      type: GENERIC_SUMMARISATION_LIST_CHANGED,
      payload: { type, metricData },
    });

    if (options.updateSortBy) {
      sortBy.onSelectSortMetrics(values, metricData);
    }
  };

  return {
    aggregateOptions,
    metricsMetaData,
    onChangeAggregateSelection,
    selectedAggregate,
    onRemoveSummarisation,
    selectedAggregateObject,
  };
};

export const useUnderlyingMetrics = (metrics) => {
  const dispatch = useDispatch();
  const [selectableUnderlyingMetrics, setSelectableUnderlyingMetrics] = useState(metrics);
  const selectedUnderlyingMetricsAsObject = useSelector(
    (state: any) => state.underlyingMetrics,
  );
  const selectedUnderlyingMetrics = lodashValues(
    selectedUnderlyingMetricsAsObject,
  );
  const summariseByObjects = useSelector(
    (state: any) => state.summarization,
  );
  const metricObjects = useSelector(
    (state: any) => state.metrics,
  );

  const prevSummariseByState: any = usePrevious(summariseByObjects);
  const prevMetricsState: any = usePrevious(metricObjects);

  const getUpdatedUnderlyingMetricsList = (ObjectCurrentState, ObjectPreviousState = {}) => {
    const newMetricAdded = Object.keys(ObjectCurrentState).filter(
      (metric) => !ObjectPreviousState[metric],
    );
    const newUnderlyingMetricsToBeAdded = newMetricAdded.filter(
      (metric) =>
        !Object.keys(selectedUnderlyingMetricsAsObject).includes(metric),
    );
    const newMetricList = [
      ...Object.keys(selectedUnderlyingMetricsAsObject),
      ...newUnderlyingMetricsToBeAdded,
    ];
    return {
      newMetricList,
      isUpdate:
        newMetricList.length > Object.keys(selectedUnderlyingMetricsAsObject).length,
    };
  };

  useEffect(() => {
    const { newMetricList, isUpdate } = getUpdatedUnderlyingMetricsList(
      summariseByObjects,
      prevSummariseByState,
    );
    if (isUpdate) onSelect(newMetricList);
  }, [summariseByObjects]);

  useEffect(() => {
    const {newMetricList, isUpdate } = getUpdatedUnderlyingMetricsList(
      metricObjects,
      prevMetricsState,
    );
    if (isUpdate) onSelect(newMetricList);
  }, [metricObjects]);

  useEffect(() => setSelectableUnderlyingMetrics(metrics), [metrics]);

  const reorderUnderlyingMetrics = (newMetricList) => {
    const newList = newMetricList.map((item) => item.value);
    onSelect(newList);
  };
  const onRemoveUnderlyingMetrics = (metricsName) => {
    const newList = selectedUnderlyingMetrics
      .filter((filter) => filter.value !== metricsName)
      .map((filter) => filter.value);
    onSelect(newList);
  };
  const onSelect = (values) => {
    const underlyingMetricData = values
      .map(
        (value) =>
          selectedUnderlyingMetricsAsObject[value] ||
          metrics.find((metric) => metric.value === value),
    );
    // using lodashCompact to remove undefined values which may arise due to custom field
    const requiredDefaultUnderlyingData = lodashCompact(underlyingMetricData).map(
      (underlyingMetric) =>
        lodashPick(underlyingMetric, ['alias', 'prettyName', 'type', 'value']),
    );
    dispatch({
      type: UNDERLYING_METRICS_CHANGED,
      payload: requiredDefaultUnderlyingData,
    });
  };

  const onChangeAlias = (name, value) => {
    dispatch({
      type: CHANGE_UNDERLYING_METRIC_ALIAS,
      payload: { value, name },
    });
  };

  return {
    onRemoveUnderlyingMetrics,
    selectableUnderlyingMetrics,
    selectedUnderlyingMetrics,
    onSelect,
    onChangeAlias,
    reorderUnderlyingMetrics,
  };
};
