import { Select, Tooltip , Divider, Space, Popover, Menu } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import filtersMap from 'src/components/common/FilterComponent';
import AdditionalFilters from './FilterComponent/additionalFilters';
import CustomDivider from './CustomDivider';
import { ReactComponent as FreezeIcon } from 'src/assets/FreezeIcon.svg';
import { ReactComponent as AdvanceFilterIcon } from 'src/assets/AdvanceFilter.svg';
import { ReactComponent as CaseInsensitiveIcon } from 'src/assets/CaseInsensitive.svg';
import { ReactComponent as CaseSensitiveIcon } from 'src/assets/CaseSensitive.svg';
import { ReactComponent as ExhaustiveIcon } from 'src/assets/ExhaustiveFilterIcon.svg';
import { dashboardFilterTypes } from 'src/constants';
import { FilterOutlined } from '@ant-design/icons';
import { FILTER_TYPE } from 'src/reduxActions/actionNameEnums';
const { Option } = Select;
const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const useStyle = createUseStyles({
    basePane: {
        display: 'flex',
        flexDirection: 'column',
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        height: '46px',
        columnGap:'4%',
    },
    filterIcon:{
        display: 'flex',
        alignItems: 'center',
    },
    filterInput:{
        width:'100%',
        display: 'flex',
        alignItems: 'center',
    },
    switch:{
        margin:'0px 6px 0px 0px',
    },
    betweenContainer: {
        display: 'flex',
        columnGap: '1rem',
    },
    active: {
        color: '#1890FF',
        fontSize: '1.25rem',
    },
    inActive:{
        color: '#8C8C8C',
        fontSize: '1.25rem',
    },
    childChartSelector: {
        display: 'flex',
        height: '2rem',
        width:'2rem',
        border: '1px solid #D9D9D9',
        borderRadius: '0.2rem',
        backgroundColor: '#FFFFFF',
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: 'center',
      },
      selectedChart: {
        borderColor: '#1890FF',
      },
      filterMenu:{
        borderRight:'0px',
      },
      dashboardFilterTypePopover:{
        '& .ant-popover-inner-content': {
          padding:'0.5rem',
        },
      },
      filterTypePopover:{
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
      },
});

const GeneralFilter = (props) => {
    const classes = useStyle();
    const dispatch = useDispatch();
    const suggestedFilterStrings = useSelector((state:any) => state.reportMetaData.suggestedFilterStrings) || [];
    let {searchOptions} = props;
    const {
        metricsName,
        type,
        filterMetaData,
        filterListRadio,
        onChangeRadio,
        onChangeFilterData,
        filterData,
        showfreezeOption,
        selectedFilterReportType,
        havingOperations,
        onChangeHavingAggregate,
        selectedHavingOperation,
        showAdditionalFilters = false,
        prettyName,
        isEditing,
        customDivider = false,
    } = props;
    const selectedValue = filterData?.[metricsName];
    const [caseInsensitiveChecked,setcaseInsensitiveChecked] = useState(filterData?.[metricsName]?.caseInsensitive);
    const [filterTypeOpen, setFilterTypeOpen]= useState(false);
    const filterTypeOptions:any = [
      {
        value: dashboardFilterTypes.EXHAUSTIVE,
        label: 'Exhaustive',
        icon: ExhaustiveIcon,
      },
      {
        value: dashboardFilterTypes.FROZEN,
        label: 'Frozen',
        icon: FreezeIcon,
      },
      {
        value: dashboardFilterTypes.SIMPLE,
        label: 'Simple',
        icon: FilterOutlined,
      },
    ];
    const storeFilterData = useSelector((state:any) => state.filterData);
    const filterType = storeFilterData?.[metricsName]?.dashboardFilterType || dashboardFilterTypes.EXHAUSTIVE;
    const switchValue = storeFilterData?.[metricsName]?.advancedFilterSwitch ??
      storeFilterData?.[metricsName]?.data?.hasOwnProperty('additionalFiterData');

    if(isEditing) {
        suggestedFilterStrings?.forEach((element) => {
            if(element.key === selectedValue?.prettyName){
                searchOptions = false;
            }
        });
    }

    const filterTypeIcon = (filterType) => {
      if (filterType === dashboardFilterTypes.EXHAUSTIVE) {
        return <ExhaustiveIcon fill={'#1890FF'} />;
      }
      if(filterType === dashboardFilterTypes.FROZEN) {
        return <FreezeIcon fill={'#1890FF'} />;
      }
      return <FilterOutlined style={{color:'#1890FF'}} />;
    };

    const onChangeFilterType = (value: string) => {
      selectedValue.dashboardFilterType = value;
      setFilterTypeOpen(false);
      dispatch({
        type: FILTER_TYPE,
        payload: {
          metricsName,
          filterType: value,
          advancedFilterSwitch: switchValue,
        },
      });
    };

    const content = (
      <Menu
        className={classes.filterMenu}
        onClick={({ key }) => onChangeFilterType(key)}
        selectedKeys={[filterType]}
      >
        {filterTypeOptions.map((option) => (
          <Menu.Item key={option.value}>
            <div className={classes.filterTypePopover}>
              <option.icon
                fill={filterType === option.value ? '#1890FF' : '#000000'}
              />{' '}
                {option.label}
            </div>
          </Menu.Item>
        ))}
      </Menu>
    );

    const showCaseInsensitive = showfreezeOption;
    const currentFilter = filterListRadio.find(filter => selectedValue.selectedFilter === filter.name);
    const FilterComp =  searchOptions ? currentFilter.type === 'inputOrMultiSelect' ?
            filtersMap.searchableSelect : filtersMap[currentFilter.type] : filtersMap[currentFilter.type];
    const handleChange = (value: string) => {
        onChangeHavingAggregate(metricsName, value);
    };
    const onChangeSwitchVisibility = (checked: boolean) => {
        dispatch({
          type: FILTER_TYPE,
          payload: {
            metricsName,
            filterType,
            advancedFilterSwitch: checked,
          },
        });
    };
    const handleHoverChange = (open: boolean) => {
      setFilterTypeOpen(open);
    };
    return (
      <div className={classes.basePane}>
        {havingOperations?.length ?
            <Select
               placeholder="Select Aggregate"
               defaultValue={selectedHavingOperation}
               getPopupContainer={(trigger) => trigger.parentNode}
               style={{ width: '100%' }}
               onChange={handleChange}
            >
            {havingOperations.map((operation) => {
                return <Option value={operation.value}>{operation.prettyName}</Option>;
            })}
        </Select> : null}
        {/* <Radio.Group onMouseLeave={() => {setButtonGroupActive(false);}}
        onMouseEnter = {() => {setButtonGroupActive(true);}}
        onChange={(e) => onChangeRadio(e.target.value)} value={selectedValue?.selectedFilter || null}> */}
            {/* {filterListRadio.map(filter => {
                const FilterComp = searchOptions ? filter.type==='inputOrMultiSelect'?
                                    FiltersMap.searchableSelect : FiltersMap[filter.type] : FiltersMap[filter.type]; */}
                <div className={classes.filterContainer}>
                    {selectedValue.selectedFilter === currentFilter.name && currentFilter.type !== 'noInput' ?
                        <div className={classes.filterInput}>
                                <FilterComp
                                    multipleSelect= {searchOptions? currentFilter.type==='inputOrMultiSelect': false}
                                    metricsName={metricsName}
                                    onChange={onChangeFilterData}
                                    value={selectedValue?.data}
                                    type={type}
                                    data={filterMetaData?.data || currentFilter.data}
                                    selectedFilterReportType={selectedFilterReportType}
                                    prettyName={prettyName}
                                    isEditing={isEditing}
                                    betweenContainer={classes.betweenContainer}
                                    style={{width:'100%',height:undefined}}
                                    size={'middle'}
                                />
                        </div>
                    :<div style={{padding:'8.25rem'}}></div>}
                    {showAdditionalFilters && ['timestampz', 'timestamp'].includes(type) ? (
                      <div className={classes.filterIcon}>
                        <Tooltip
                          title={
                            'Advanced filters settings, e.g. time for date-time fields, day of the week selection etc'
                           }
                           placement="topLeft"
                        >
                          <div
                            onClick={() => onChangeSwitchVisibility(!switchValue)}
                            className={`${classes.childChartSelector}${
                              switchValue ? ' ' + classes.selectedChart : ''
                            }`}
                            style={{paddingTop:'0.3rem', paddingLeft:'0.3rem'}}
                           >
                            <AdvanceFilterIcon fill={switchValue ? '#1890FF' : '#8C8C8C'}/>
                          </div>
                        </Tooltip>
                      </div>
                    ) : null}
                    {showfreezeOption ? (
                      <Popover
                        placement="rightTop"
                        content={content}
                        onVisibleChange={handleHoverChange}
                        visible={filterTypeOpen}
                        overlayClassName={classes.dashboardFilterTypePopover}
                      >
                        <div className={classes.filterIcon}>
                          <div
                            className={classes.childChartSelector}
                            style={{ borderColor: '#1890FF'}}
                           >
                            {filterTypeIcon(filterType)}
                           </div>
                      </div>
                      </Popover>
                    ):null}
                    {selectedValue.type ==='text' && showCaseInsensitive ? (
                       <div className={classes.filterIcon}>
                         <Tooltip
                          title={caseInsensitiveChecked ? 'Case Insensitive' : 'Case Sensitive'}
                          placement="topLeft"
                         >
                            <div
                              onClick={() =>
                                {
                                    selectedValue.caseInsensitive = !caseInsensitiveChecked;
                                    setcaseInsensitiveChecked(!caseInsensitiveChecked);
                                }
                              }
                              className={`${classes.childChartSelector}${
                                caseInsensitiveChecked ? ' ' + classes.selectedChart : ''}`}
                            >
                              {caseInsensitiveChecked ? <CaseInsensitiveIcon/> : <CaseSensitiveIcon/> }
                            </div>
                         </Tooltip>
                        </div>
                    ) : null}
                </div>
            {/* })} */}
        {/* </Radio.Group> */}
        <Space direction="vertical" size="small" style={{ display: 'flex' }}>
        {
            switchValue ?
                <AdditionalFilters
                    metricsName={metricsName}
                    onChangeFilterData={onChangeFilterData}
                    filterData={filterData}
                />
            : null
        }
        </Space>
        {customDivider ? <CustomDivider style={{ marginTop: '0.8rem' }} /> : null}
      </div>
    );
};

export default GeneralFilter;
