import React, { memo, useEffect, useState } from 'react';
import { Input, Button, Row, Col } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { createUseStyles } from 'react-jss';
import lodash from 'lodash';

const useStyles = createUseStyles({
    addButton: {
        color: '#006EC3',
        border: 'none',
    },
    removeButton: {
        border: 'none',
        color: '#FF4D4F',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            color: '#FF7875',
        },
    },
    inputLabel: {
        color: 'rgba(0, 0, 0, 0.45)',
    },
    entryRow: {
        marginBottom: '1em',
    },
});

interface IAdditionalFields {
    key?: string,
    value?: string,
}

const AdditionalFieldSection = (props) => {
    const { disabled, onChange, value:additionalFields = [{ key: '', value: '' }] } = props;
    const classes = useStyles();

    const onAdditionalFieldskeyChange = (index: number, event) => {
        const newAdditionalFields = [...additionalFields];
        newAdditionalFields[index].key = event.target.value;
        onChange(newAdditionalFields);
    };

    const onAdditionalFieldsValueChange = (index: number, event) => {
        const newAdditionalFields = [...additionalFields];
        newAdditionalFields[index].value = event.target.value;
        onChange(newAdditionalFields);
    };

    const handleAddInput = () => {
        const newAdditionalFields = [...additionalFields, { key: '', value: '' }];
        onChange(newAdditionalFields);
    };

    const handleRemoveInput = (index: number) => {
        const newAdditionalFields = [...additionalFields];
        newAdditionalFields.splice(index, 1);
        onChange(newAdditionalFields);
    };

    return (
        <>
            <Row gutter={[8, 8]}>
                <Col span={10}><span className={classes.inputLabel}>Key</span></Col>
                <Col span={10}><span className={classes.inputLabel}>Value</span></Col>
                <Col span={4}>
                    <Button
                        className={classes.addButton}
                        type="link"
                        shape="circle"
                        icon={<PlusCircleOutlined />}
                        onClick={handleAddInput}
                        disabled={disabled}
                    />
                </Col>
            </Row>
            {
                additionalFields.map((additionalField, index) => (
                    <div key={index}>
                        <Row gutter={[8, 8]} className={classes.entryRow}>
                            <Col span={10}>
                                <Input
                                    value={additionalField.key}
                                    onChange={(event) => onAdditionalFieldskeyChange(index, event)}
                                    disabled={disabled}
                                />
                            </Col>
                            <Col span={10}>
                                <Input
                                    value={additionalField.value}
                                    onChange={(event) => onAdditionalFieldsValueChange(index, event)}
                                    disabled={disabled}
                                />
                            </Col>
                            <Col span={4}>
                                <Button
                                    type="link"
                                    shape="circle"
                                    className={classes.removeButton}
                                    icon={<MinusCircleOutlined />}
                                    onClick={() => handleRemoveInput(index)}
                                    disabled={disabled}
                                />
                            </Col>
                        </Row>
                    </div>
                ))
            }
        </>
    );
};

export default memo(AdditionalFieldSection);
