import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetChartConfigData } from 'src/components/common/utils/utils';
import G6 from '@antv/g6';
import { transformBarChartData } from 'src/components/utils/utils';

const fittingString = (str, maxWidth, fontSize, toFormat) => {
  if (toFormat) {
    return str;
  }
  const ellipsis = '..';
  const ellipsisLength = G6.Util.getTextSize(ellipsis, fontSize)[0];
  let currentWidth = 0;
  let res = str;

  str.split('').forEach((letter, i) => {
    if (currentWidth > maxWidth - ellipsisLength) return;
    // get the width of single letter according to the fontSize
    currentWidth += G6.Util.getLetterWidth(letter, fontSize);

    if (currentWidth > maxWidth - ellipsisLength) {
      res = `${str.substr(0, i - 1)}${ellipsis}`;
    }
  });
  return res;
};

const BarConfigs = (props) => {
  const {
    dimensions,
    XAxisGroups,
    chartConfig,
    formData,
    chartsFromDashboard,
    chartData,
  } = props;
  const configObject = {
    chartConfig,
    configType: 'bar',
    formData,
    chartsFromDashboard,
  };
  const configData = GetChartConfigData(configObject);
  if (!configData) {
    return null;
  }
  const { yAxis, xAxis, graphicLabel: label, legend } = configData['bar'] || {};
  const { tooltip, color, columnSize, percentageThreshold, colorMap } =
    configData['graphic'] || {};
  const {
    location,
    flipPage,
    offsetX: legendOffsetX,
    offsetY: legendOffsetY,
  } = configData['legend'] || {};
  const { position, offsetX, offsetY, labelSize } =
    configData['graphicLabel'] || {};
  const {
    autoHideLabel: xAutoHide,
    autoRotateLabel: xAutoRotate,
    axisRulers: xLine,
    grid: xGrid,
    tickLine: xTickLine,
    xAxisLabel,
    xTitle,
  } = configData['xAxis'] || {};
  const {
    autoHideLabel: yAutoHide,
    autoRotateLabel: yAutoRotate,
    axisRulers: yLine,
    grid: yGrid,
    tickLine: yTickLine,
    yAxisLabel,
    yTitle,
  } = configData['yAxis'] || {};
  const finalData = transformBarChartData(
    chartData,
    percentageThreshold?.data ?? 0,
  );
  const colorSet = new Set();
  let colorArray: any = [];
  finalData.forEach((item, index) => {
    if (colorMap) {
      const color = colorMap.data[item.value];
      if (color && !colorSet.has(color)) {
        colorSet.add(color);
        colorArray.push(color);
      }
    }
  });
  colorArray = [...colorArray, ...color?.data];

  const barConfig = {
    yAxis: yAxis?.data
      ? {
          grid: {
            visible: yGrid?.data,
            line: {
              style: {
                stroke: '#e8e8e8',
                lineWidth: 1,
              },
            },
          },
          tickLine: yTickLine?.data ? {} : undefined,
          line: yLine?.data ? {} : undefined,
          label: yAxisLabel?.data
            ? {
                style: {
                  fill: '#000000',
                },
                autoHide: yAutoHide?.data,
                autoRotate: yAutoRotate?.data,
                offsetX: configData['yAxisLabel']?.offsetX?.data,
                offsetY: configData['yAxisLabel']?.offsetY?.data,
              }
            : false,

          title: yTitle?.data
            ? {
                text: configData['yTitle']?.text?.data,
              }
            : undefined,
        }
      : false,
    xAxis: xAxis?.data
      ? {
          grid: {
            visible: xGrid?.data,
            line: {
              style: {
                stroke: '#e8e8e8',
                lineWidth: 1,
              },
            },
          },
          tickLine: {
            alignTick: xTickLine?.data,
          },
          line: xLine?.data ? {} : undefined,
          label: xAxisLabel?.data
            ? {
                style: {
                  fill: '#000000',
                },
                autoHide: xAutoHide?.data,
                autoRotate: xAutoRotate?.data,
                offsetX: configData['xAxisLabel']?.offsetX?.data,
                offsetY: configData['xAxisLabel']?.offsetY?.data,
                formatter: (string) =>
                  fittingString(
                    string,
                    dimensions.width / (XAxisGroups.length * 1.2),
                    10,
                    configData['xAxis']?.autoHideLabel?.data ||
                      configData['xAxis']?.autoRotateLabel?.data,
                  ),
              }
            : false,
          title: xTitle?.data
            ? {
                text: configData['xTitle']?.text?.data,
              }
            : undefined,
        }
      : false,
    label: label?.data
      ? {
          position: position?.data,
          offsetX: offsetX?.data,
          offsetY: offsetY?.data,
          style: {
            fontSize: labelSize?.data,
          },
        }
      : undefined,
    legend: legend?.data
      ? {
          position: location?.data,
          flipPage: flipPage?.data,
          offsetX: legendOffsetX?.data,
          offsetY: legendOffsetY?.data,
        }
      : false,
    barWidthRatio:
      columnSize?.data === 'customize'
        ? configData['columnSize']?.thicknessValue?.data / 100
        : 0.7,
    columnWidthRatio:
      columnSize?.data === 'customize'
        ? configData['columnSize']?.thicknessValue?.data / 100
        : 0.7,
    tooltip: tooltip?.data,
    color: colorArray,
    othersThreshold: percentageThreshold?.data,
    data: finalData,
  };
  return barConfig;
};

export default BarConfigs;
