import { useState, useEffect } from 'react';
import lodashKeys from 'lodash/keys';
import lodashUnion from 'lodash/union';
import lodashMapKeys from 'lodash/mapKeys';
import { useDispatch, useSelector } from 'react-redux';
import {
  FILTER_DATA_RESET,
  INITIALIZE_AGGREGATE,
  INITIALIZE_METRICES,
  INITIALIZE_FILTERS,
  SELECTED_REPORT_CHANGED,
  SET_CHART_TYPE,
  INITIALIZE_SORT,
  INITIALIZE_CHARTOPTIONS,
  INITIALIZE_CHARTCONFIG,
  INITIALIZE_CONDITIONAL_FORMATTING,
  SET_MASTER_DATA,
  RESET_CHART_OPTIONS,
  METRICS_RESET,
  SORT_DATA_RESET,
  SUMMARISATION_LIST_RESET,
  METRICS_CHANGED,
  RESET_CUSTOM_COLUMNS_GLOBAL,
  INITIALISE_REPORT_USER_TYPE,
  INITIALISE_REPORT_NAME,
  INITILISE_REPORT_DESCRIPTION,
  INITILISE_METRIC_TYPE,
  RESET_REPORT_NAME_AND_DESCRIPTION_AND_ID,
  INITIALISE_REPORT_ID,
  RESET_CUSTOM_COLUMNS_LOCAL,
  SUGGESTED_FILTER,
  RESET_CHART,
  COLUMN_NAME,
  HAVING_INITIALIZE_FILTERS,
  HAVING_FILTER_DATA_RESET,
  SELECTED_DBTYPE_CHANGED,
  GENERIC_INITIALIZE_FILTERS,
  GENERIC_FILTER_DATA_RESET,
  GENERIC_INITIALIZE_AGGREGATE,
  INITIALISE_OBJECT,
  RESET_OBJECT,
  INITIALISE_REPORT_CATEGORY,
  GENERIC_SUMMARISATION_LIST_CHANGED,
  AUTOMATION_INITIALISE,
  INITIALIZE_UNDERLYING_METRICS,
  SET_TABLE_PAGE_SIZE,
  CHART_OPTION_VALUE_CHANGED,
  ACTION_INITIALISE,
  ACTION_CHANGED,
  UNDERLYING_METRICS_RESET,
  SET_REPORT_MASTER_DATA,
  SET_IS_AGGREGATED,
  ADMIN_REPORT_SWITCH,
  CHANGE_METRIC_STRING_MANUAL_QUERY,
  CUSTOM_QUERY_STRING,
  CUSTOM_FILTER_DATA_CHANGED,
  RESET_AUTOMATION_DATA,
  SET_HTML_TEXT,
  SELECTED_DATA_MART_TYPE_CHANGED,
  CHANGE_MONGO_COLLECTION_NAME,
  UNDERLYING_CUSTOM_QUERY_STRING,
} from 'src/reduxActions/actionNameEnums';
import {  buildObjectFromCustomColumnObj } from '../dataSetupContainerPart/customColumnsDrawer';
import { generateMetricArray, isManualQueryReport } from 'src/components/common/utils/utils';
import { isReportTypeHtmlEditior, isHtmlSupported } from 'src/components/utils/utils';

export const useReport = (defaultDataObject:any = null, isMetricPage = false) => {
    const [chartData, setChartData] = useState<any>();
    const [reportList, setReportList] = useState<any[]>([]);
    const selectedReport = useSelector((state:any) => state.reportMetaData.selectedReport);
    const selectedDataMartType = useSelector((state: any) => state.reportMetaData.dataMartType);
    const setSelectedReport = (value) => dispatch({type:SELECTED_REPORT_CHANGED, payload: value});
    const setDataMartType = (value) => dispatch({type:SELECTED_DATA_MART_TYPE_CHANGED, payload: value});
    const setSelectedReportDbType = (value) => dispatch({ type: SELECTED_DBTYPE_CHANGED, payload: value });
    let metrics = useSelector((state:any) => state.reportConfig.reports?.[selectedReport]?.metrics) || [];
    const dispatch = useDispatch();
    const masterDataInState = useSelector((state:any) => state.masterData);
    const reportConfig = useSelector((state:any) => state.reportConfig);
    const isBeingEdited = useSelector((state:any) => state.reportMetaData.reportID);
    const filtersMetaData = useSelector((state:any) => state?.masterData?.supportedFilters);

    useEffect(() => {
      if(reportList.length > 0){
        const reportsDefaultSelectionLocal =  reportList[0].value;
        const defaultReportDbType =  reportList[0].dbType;
        if(!selectedReport){
          setSelectedReport(reportsDefaultSelectionLocal);
          setSelectedReportDbType(defaultReportDbType);
        }
      }
    },[reportList]);

    useEffect(() => {
      if (masterDataInState && reportConfig) {
        const reportMasterData = reportConfig.reports;
        setChartData(reportMasterData);
        const reportKeyList = lodashKeys(reportMasterData);
        let reportListLocal = reportKeyList.map((reportKey) => {
            return {
              prettyName: reportMasterData[reportKey].reportPrettyName,
              description: reportMasterData[reportKey].description,
              value: reportKey,
              dbType: reportMasterData[reportKey].dbType,
              type: reportMasterData[reportKey].type,
            };
        });
        if(isMetricPage){
          reportListLocal = reportListLocal.filter((report) => !isReportTypeHtmlEditior(report.value));
        }
        setReportList(reportListLocal);
        defaultDataObject?.needToSetInitialValue && defaultDataObject.setDataInRedux();
        if (defaultDataObject?.needToSetInitialValue) {
          if (!isManualQueryReport(defaultDataObject?.needToSetInitialValue.dataMartType)) {
            const customMetricesAggregate = modifyMetricesForCustomColumnAggregate(defaultDataObject.needToSetInitialValue);
            const customMetrices = modifyMetricesForCustomColumn(defaultDataObject.needToSetInitialValue);
            const customMetricesGlobal = customMetrices.map(items => buildObjectFromCustomColumnObj(items, 'global'));
            const customMetricesLocal = customMetricesAggregate?.map(items => buildObjectFromCustomColumnObj(items,'local'));
            const selectedReport = defaultDataObject.needToSetInitialValue.reportType;
            let metrics = reportConfig?.reports?.[selectedReport]?.metrics;
            metrics = metrics?.filter(item => !item.customColumnType);
            metrics = [...metrics, ...customMetricesGlobal];
            const payload = {
                ...reportConfig,
                reports:{
                    ...reportConfig.reports,
                    [selectedReport]: {
                        ...reportConfig.reports[selectedReport],
                        metrics,
                    },
                },
            };
            dispatch({type: SET_REPORT_MASTER_DATA , payload});
            const metricData = defaultDataObject.needToSetInitialValue.metrices;
            customMetricesLocal.forEach(item => {
                metricData.push(item);
            });
            metricData.sort((a, b) => (a.index >= b.index ? 1 : -1));
            dispatch({
                type: METRICS_CHANGED,
                payload: metricData,
            });
          }
        }
      }
    }, []);

    const resetEverything = (selectedReport: string | null = null) => {
      dispatch({ type: FILTER_DATA_RESET });
      dispatch({ type: GENERIC_FILTER_DATA_RESET });
      dispatch({ type: RESET_CHART_OPTIONS });
      dispatch({ type: HAVING_FILTER_DATA_RESET });
      dispatch({ type: ADMIN_REPORT_SWITCH, payload: { isAdminReport: false }});
      dispatch({ type: CHANGE_METRIC_STRING_MANUAL_QUERY, payload: { metricString: '' } });
      dispatch({ type: CHANGE_MONGO_COLLECTION_NAME, payload: undefined });
      dispatch({ type: CUSTOM_QUERY_STRING, payload: { queryString: '' } });
      dispatch({ type: UNDERLYING_CUSTOM_QUERY_STRING, payload: { queryString: '' } });
      let dataMartType;
      if (selectedReport) {
        dataMartType = reportConfig.reports[selectedReport].type;
        metrics = reportConfig.reports[selectedReport]?.metrics;
      }
      dispatch({
        type: SELECTED_DATA_MART_TYPE_CHANGED,
        payload: dataMartType,
      });
      const requiredFilterKeys = selectedReport
        ? metrics.filter(metric => metric?.filterMetaData?.required)
        : [];
      dispatch({
        type: GENERIC_SUMMARISATION_LIST_CHANGED,
        payload: { type: 'timeline', metricData: requiredFilterKeys },
      });
      dispatch({ type: INITIALIZE_FILTERS, payload: { metricData: requiredFilterKeys, filtersMetaData }});
      dispatch({ type: GENERIC_INITIALIZE_FILTERS, payload: { metricData: { coverage: [] }, filtersMetaData }});
      dispatch({ type: HAVING_INITIALIZE_FILTERS, payload: { metricData: [], filtersMetaData }});
      dispatch({ type: SUMMARISATION_LIST_RESET });
      dispatch({ type: METRICS_RESET });
      dispatch({ type: RESET_OBJECT });
      dispatch({ type: SORT_DATA_RESET });
      dispatch({ type: RESET_CHART_OPTIONS });
      dispatch({ type: UNDERLYING_METRICS_RESET });
      const initialChartType = isHtmlSupported(selectedReport) ? 'html': 'table';
      dispatch({ type: SET_CHART_TYPE, payload: { chartType: defaultDataObject?.options?.defaultChartType || initialChartType } });
      dispatch({ type: ACTION_INITIALISE });
      dispatch({ type: RESET_AUTOMATION_DATA });
      if(!isBeingEdited){
        dispatch({ type: RESET_REPORT_NAME_AND_DESCRIPTION_AND_ID });
      }
    };
    useEffect(() => {
      if (selectedReport && !defaultDataObject?.needToSetInitialValue) {
        resetEverything(selectedReport);
      }
    }, [selectedReport]);
    return {
      reportList,
      selectedReport,
      setSelectedReport,
      setSelectedReportDbType,
      setDataMartType,
      selectedDataMartType,
      metrics,
      resetEverything,
    };
};
const modifyMetricesForCustomColumnAggregate = (data) => {
  if(!data) return;
  const metricesAggregate = data.customAggregateMetrices?.map(item => {
    return {
      ...item,
      customColumnType: 'local',
      sql: item.sql,
      metric: item.metric,
      type: item.type,
      value: item.metric,
      name: item.metric,
      alias: item.alias,
      opeations: null,
    };
  });
  return metricesAggregate?.filter(item => !(item === null));
};

const removeMatchingUnderlyingMetrics = (underlyingMetrics, metrices) => {
  return underlyingMetrics.filter((underlyingMetric) => {
    return !metrices.some((metric) => metric?.value === underlyingMetric?.value);
  });
};

const getCustomMetrices = (data,dataCustomMetrices) => {
  if(!data || !dataCustomMetrices){
    return [];
  }
  const customMetrices = data?.map(items => {
    const index = dataCustomMetrices?.findIndex(item => item.metric === items.value);
    if(index === -1){
      return null;
    }
    return {
      ...items,
      customColumnType: 'global',
      sql: dataCustomMetrices[index].sql,
      metric: dataCustomMetrices[index].metric,
      type: dataCustomMetrices[index].type,
      value: dataCustomMetrices[index].metric,
      name: dataCustomMetrices[index].metric,
    };
  });
  return customMetrices;
};

const modifyMetricesForCustomColumn = (data) => {
  if(!data) return;
  const metrices = getCustomMetrices(data?.metrices,data?.customMetrices);
  const filters = getCustomMetrices(data?.filters,data?.customMetrices);
  const underlyingMetrics = getCustomMetrices(data?.underlyingMetrics,data?.customMetrices);
  let updatedUnderlyingMetrics = removeMatchingUnderlyingMetrics(underlyingMetrics,metrices);
  updatedUnderlyingMetrics = removeMatchingUnderlyingMetrics(updatedUnderlyingMetrics,filters);
  const customMetrices = lodashUnion(metrices, filters, updatedUnderlyingMetrics);
  return customMetrices.filter(item => !(item === null));
};

const sortByAdditionForCustomColumnAggregate = (existingMetric,data) => {
  data.customAggregateMetrices.forEach(item => {
    if(!item.sortBy) return;
    existingMetric.push({
      ...item,
      customColumnType: 'local',
      metric: item.metric,
      type: item.type,
      value: item.metric,
      name: item.metric,
      alias: item.alias,
      sortBy: item.sortBy,
    });
  });
  return existingMetric.sort((firstMetric,secondMetric) => firstMetric.sortBy.index - secondMetric.sortBy.index);
};

const getPivotMetrics = (data, name, metrices) => {
  const section = data.chartOptions?.find(
    (option) => option.section === name,
  )?.data;
  const parsedValue = section ? JSON.parse(section)?.value : [];
  const metrics = metrices.filter((metric) =>
    parsedValue.includes(metric.value),
  );
  return metrics;
};
export const useSetInitialReportData = (data) => {
  const reportConfig = useSelector((state: any) => state?.reportConfig.reports) || {};
  const dispatch = useDispatch();
  const setSelectedReport = (value) => dispatch({ type: SELECTED_REPORT_CHANGED, payload: value });
  const initializeDataMartType = (value) => dispatch({ type: SELECTED_DATA_MART_TYPE_CHANGED, payload: value });
  const initializeDbType = (value) => dispatch({ type: SELECTED_DBTYPE_CHANGED, payload: value });
  const onChartTypeChanged = (value) => dispatch({ type: SET_CHART_TYPE, payload: { chartType: value }});
  const initializeAggregate = (value) => dispatch({ type: INITIALIZE_AGGREGATE, payload: value});
  const initializeGenericAggregate = (value) => dispatch({ type: GENERIC_INITIALIZE_AGGREGATE, payload: value});
  const initializeMetrices = (value) => dispatch({ type: INITIALIZE_METRICES, payload: value });
  const initializeUnderlyingMetrics = (value) => dispatch({ type: INITIALIZE_UNDERLYING_METRICS, payload: value});
  const initializeFilters = (value) => dispatch({ type: INITIALIZE_FILTERS, payload: value });
  const initializeGenericFilters = (value) => dispatch({ type: GENERIC_INITIALIZE_FILTERS, payload: value });
  const initializeHavingFilters = (value) => dispatch({ type: HAVING_INITIALIZE_FILTERS, payload: value });
  const initializeSortBy = (value) => dispatch({ type: INITIALIZE_SORT, payload: value });
  const initializeChartOptions = (value) => dispatch({ type: INITIALIZE_CHARTOPTIONS, payload: value});
  const initializeChartConfig = (value) => dispatch({ type: INITIALIZE_CHARTCONFIG, payload: value});
  const initializeConditionalFormatting = (value) => dispatch({ type: INITIALIZE_CONDITIONAL_FORMATTING, payload: value});
  const initialisePageSize = (value) => dispatch({ type: SET_TABLE_PAGE_SIZE, payload: value});
  const initializeCustomMetrices = (value) => dispatch( {type: RESET_CUSTOM_COLUMNS_GLOBAL, payload: value});
  const initializeCustomMetricesAggregate = (value) => dispatch({type: RESET_CUSTOM_COLUMNS_LOCAL, payload: value});
  const initialiseReportType = (value) => dispatch({ type: INITIALISE_REPORT_USER_TYPE, payload: value });
  const initialiseReportInfo = (value) => dispatch({ type: INITIALISE_REPORT_NAME, payload: value });
  const initialiseObject = (value) => dispatch({ type: INITIALISE_OBJECT, payload: value });
  const initialiseReportDescription = (value) => dispatch({ type: INITILISE_REPORT_DESCRIPTION, payload: value});
  const initialiseMetricType = (value) => dispatch({ type: INITILISE_METRIC_TYPE, payload: value});
  const initialiseReportID = (value) => dispatch({ type: INITIALISE_REPORT_ID, payload: value });
  const initialiseChart = () => dispatch({ type: RESET_CHART });
  const initialiseSuggestedFilters = (value) => dispatch({ type: SUGGESTED_FILTER, payload: value });
  const initialiseColumnName = (value) => dispatch({ type: COLUMN_NAME, payload: value });
  const filtersMetaData = useSelector((state:any) => state?.masterData?.supportedFilters);
  const masterData = useSelector((state:any) => state?.masterData);
  const initialiseReportCategory = (value) => dispatch({ type: INITIALISE_REPORT_CATEGORY, payload: value });
  const initialiseAutomation = (value) => dispatch({ type: AUTOMATION_INITIALISE, payload: value });
  const initialiseAggregate = (value) => dispatch({type: SET_IS_AGGREGATED, payload: value});
  const initialisePivotTable = (value) => dispatch({ type: CHART_OPTION_VALUE_CHANGED, payload: value });
  const initialiseActionData = (value) => dispatch({ type: ACTION_CHANGED, payload: value });
  const initialiseQueryString = value =>
    dispatch({ type: CUSTOM_QUERY_STRING, payload: { queryString: value } });
  const initialiseUnderlyingQueryString = (value) =>
    dispatch({ type: UNDERLYING_CUSTOM_QUERY_STRING, payload: { queryString: value } });
  const initialiseCustomReportMetrics = (value) => dispatch({ type: CHANGE_METRIC_STRING_MANUAL_QUERY, payload: value });
  const initialiseMongoCollectionName = (value) => dispatch({ type: CHANGE_MONGO_COLLECTION_NAME, payload: value });
  const initialiseAdminReportSwitch = (value) => dispatch({ type: ADMIN_REPORT_SWITCH, payload: { isAdminReport: value } });
  const initialiseCustomReportFilters = (value) => dispatch({ type: CUSTOM_FILTER_DATA_CHANGED, payload: value });
  const initialiseHtmlData = (value) => dispatch({ type: SET_HTML_TEXT, payload: value });
  const initialisetDataMartType = (value) => dispatch({type:SELECTED_DATA_MART_TYPE_CHANGED, payload: value});

  const setDataInRedux = () => {

    if (data) {
      setSelectedReport(data.reportType);
      initializeDataMartType(data.dataMartType);
      initializeDbType(reportConfig[data.reportType]?.dbType);
      onChartTypeChanged(data.chartType);
      if(data.metrices) {
        const aggregate = data.metrices.filter(metric => metric.groupBy?.toApply);
        initializeGenericAggregate({ type: 'timeline', value: aggregate });
        initializeGenericAggregate({ type: 'summariseBy', value: aggregate });
        if (isManualQueryReport(data.dataMartType)) {
          initialiseCustomReportMetrics({ metricString: data.metricString });
          const metricArray = JSON.parse(data.metricString);
          const summarizeArray = metricArray.filter(({ groupBy }) => !!(groupBy && groupBy.toApply));
          const summarizeObjectArray = generateMetricArray(summarizeArray);
          initializeAggregate(summarizeObjectArray);
          initializeMetrices(data.metrices);
        } else {
          initializeMetrices(data.metrices);
          initializeAggregate(aggregate.filter((agg) => agg.section == null));
        }
        let sortBy = data.metrices.filter(metric => metric.sortBy);
        sortBy = sortByAdditionForCustomColumnAggregate(sortBy,data);
        initializeSortBy(sortBy);
        initializeUnderlyingMetrics(data.underlyingMetrics || []);
      }
      initialisetDataMartType(data.dataMartType);
      initializeChartOptions(data.chartOptions);
      initializeChartConfig(data.chartConfig);
      initializeConditionalFormatting(data.formatting);
      if (!isManualQueryReport(data.dataMartType)) {
        const metricMetaData = reportConfig[data.reportType]?.metrics || [];
        const metricData = data.filters.filter((filter) => filter.section == null);
        const filters = metricData.map((filter) => {
          const filterMetaData = metricMetaData.find((metric) => metric.value === filter.value)?.filterMetaData;
          if(!filterMetaData) return filter;
          return {
            ...filter,
            filterMetaData,
          };
        });
        initializeFilters({ metricData: filters, filtersMetaData });
      } else {
        initialiseCustomReportFilters(lodashMapKeys(data.filters, (data) =>  data.value));
      }
      if(isManualQueryReport(data.dataMartType) && data.mongoCollectionName){
        initialiseMongoCollectionName(data.mongoCollectionName);
      }
      initialiseObject(data.object);
      initializeGenericFilters({ metricData: data.filters, filtersMetaData, filterType: 'coverage' });
      initializeHavingFilters({ metricData: data.havingFilters, filtersMetaData });
      initializeCustomMetrices(data.customMetricesObj);
      initializeCustomMetricesAggregate(data.customAggregateMetricesObj);
      initialiseReportType(data.reportUserType);
      initialiseReportInfo(data.name);
      initialiseReportDescription(data.description);
      initialiseMetricType(data.metricType);
      initialiseReportID(data.id);
      initialiseChart();
      initialiseSuggestedFilters(data.suggestedFilterStrings);
      initialiseColumnName('');
      initialiseReportCategory(data.reportCategory);
      initialisePageSize(data.pageSize);
      initialiseAggregate(data.isAggregate);
      initialiseActionData(data.actions || []);
      initialiseQueryString(data.queryString || '');
      initialiseUnderlyingQueryString(data.underlyingQueryString || '');
      initialiseAdminReportSwitch(data.isAdminReport || false);
      const initializePivotTable = (optionName, value) => {
        const section = 'rowColumns';
        initialisePivotTable({
          optionName,
          value,
          section,
        });
      };
      if (data.chartType === 'pivotTable') {
        const metrices = data.metrices.map((metric) => ({
          ...metric,
          chartSortBy: 'ASC',
        }));
        const rowMetrics = getPivotMetrics(data, 'rows', metrices);
        const columnMetrics = getPivotMetrics(data, 'columns', metrices);
        if(rowMetrics.length !== 0){
          initializePivotTable('row', rowMetrics);
          initializePivotTable('column', columnMetrics);
        }
      }

      const automationData = data.automationSchedules?.length ? { ...data.automationSchedules[0] } : {};
      if (automationData.intervalType) {
        automationData.selectedInterval = automationData.intervalType;
      }

      initialiseAutomation(automationData);
      initialiseHtmlData(data.htmlData);
    }
  };

  return { setDataInRedux, needToSetInitialValue: data };
};

export const useRemoveReportData = () => {
  const dispatch = useDispatch();
  const removeData = () => {
    dispatch({ type: FILTER_DATA_RESET });
    dispatch({ type: GENERIC_FILTER_DATA_RESET });
    dispatch({ type: RESET_CHART_OPTIONS });
    dispatch({ type: HAVING_FILTER_DATA_RESET });
    dispatch({ type: RESET_CUSTOM_COLUMNS_GLOBAL, payload: {} });
    dispatch({ type: RESET_CUSTOM_COLUMNS_LOCAL, payload: {} });
    dispatch({ type: SUMMARISATION_LIST_RESET });
    dispatch({ type: METRICS_RESET });
    dispatch({ type: RESET_OBJECT });
    dispatch({ type: SORT_DATA_RESET });
    dispatch({ type: RESET_CHART_OPTIONS });
    dispatch({ type: UNDERLYING_METRICS_RESET });
    dispatch({ type: RESET_REPORT_NAME_AND_DESCRIPTION_AND_ID });
    dispatch({ type: ACTION_INITIALISE });
    dispatch({ type: RESET_AUTOMATION_DATA });
  };
  return { removeData };
};
