import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { GET_REPORT_VISUALISATION } from './../hooks/dashboardQueries';
import {
  DASHBOARD_FILTER_UPDATED,
  SET_LAST_UPDATED_TIME,
  SET_REPORT_LIST,
  SET_MOBILE_REPORT_PANE_HEIGHT,
  DASHBOARD_REPORT_CHART_CONFIG,
} from 'src/reduxActions/actionNameEnums';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashCloneDeep from 'lodash/cloneDeep';

const useChartContainerHook = (reportId, filters, listenForRefresh, dashboardTab) => {
    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [formData, setFormData] = useState<any>('{}');
    const [chartData, setChartData] = useState('{}');
    const [visualizeMetaData, setVisualizeMetaData] = useState('{}');
    const [chartType, setChartType]= useState();
    const [dateRange, setDateRange] = useState('');
    const [chartConfig, setChartConfig] = useState('{}');
    const [formatting, setFormatting] = useState('{}');
    const [chartOptions, setChartOptions] = useState('{}');
    const [columnWidth, setColumnWidth] = useState('{}');
    const [reportDescription, setReportDescription] = useState('');
    const [htmlData, setHtmlData] = useState('');
    const [reportType, setReportType] = useState('');
    const [newRelicUrl, setNewRelicUrl] = useState('');
    const [lastRefreshTime, setLastRefreshTime] = useState('');
    const enableDummyData = useSelector((state:any) => state.reportMetaData.dummyDataSwitch);
    const { lastUpdatedTime, reportsData, isAutoRefresh } = useSelector((state: any) => state.dashboardGroupMetaData);
    const reportList = lodashCloneDeep(useSelector((state: any) => state.dashboardGroupMetaData.reportList));
    const isTabletOrMobile = useSelector(
        (state: any) => state.config.isTabletOrMobile,
    );
    const dashboardGroupId = useSelector((state: any) => state.dashboardGroupId);
    const cachedDashboardString = localStorage.getItem('activeDashboard');
    const cachedDashboards = cachedDashboardString ? JSON.parse(cachedDashboardString) : null;
    const activeCachedDashboard = cachedDashboards?.find(activeDashboard => activeDashboard.dashboardGroupId === dashboardGroupId) ?? null;
    const filterUpdated = useSelector((state: any) => state.dashboardGroupMetaData.filterUpdated);
    const externalFilters = useSelector((state: any) => state.config.externalFilters);

    const setData = (data) => {
        setFormData(JSON.parse(data.formData || '{}'));
        setChartData(JSON.parse(data.chartData || '{}'));
        setChartType(data.chartType);
        setDateRange(data.reportDateRange);
        setVisualizeMetaData(JSON.parse(data?.visualizeMetaData || '{}'));
        setLoaded(true);
        setChartConfig(JSON.parse(data.chartConfig));
        setFormatting(JSON.parse(data.formatting|| null)?? []);
        setChartOptions(data.chartOptions);
        setColumnWidth(JSON.parse(data.columnWidth || null));
        setReportDescription(data.reportDescription);
        setHtmlData(data.htmlData);
        setReportType(data.reportType);
        setNewRelicUrl(data.url);
        setLastRefreshTime(data.lastRefreshTime);
    };

    const [getReportVisualisation, { loading: fetchReport, data: reportData }] = useLazyQuery(GET_REPORT_VISUALISATION, {
        fetchPolicy: 'no-cache',
        onCompleted: ({ getReportVisualisation: data }) => {
            setData(data);
            if(data.chartType === 'number' && isTabletOrMobile) {
                dispatch({
                    type: SET_MOBILE_REPORT_PANE_HEIGHT,
                    payload: {
                        reportId,
                        bigNumberCount: JSON.parse(data.chartData).length,
                    },
                });
            }
            if(isTabletOrMobile && filterUpdated) {
                dispatch({ type: DASHBOARD_FILTER_UPDATED, payload: false });
            }
            if(isTabletOrMobile) {
                const chartConfig = JSON.parse(data.chartConfig || '{}');
                dispatch({
                    type: DASHBOARD_REPORT_CHART_CONFIG,
                    payload: {
                        reportId,
                        chartConfig,
                    },
                });
            }
            const now = Date.now();
            if(lastUpdatedTime < now){
                dispatch({ type: SET_LAST_UPDATED_TIME, payload: now });
            }
            const index = reportList.indexOf(reportId);
            if (index !== -1) {
                reportList.splice(index, 1);
                dispatch({
                    type: SET_REPORT_LIST,
                    payload: reportList,
                  });
            }
        },
        onError: (error) => {
            if(error?.networkError?.statusCode === 403) {
                setError(true);
            }
        },
    });
    const getReportVisualisationAPI = (reportId, dashboardId, globalFilters, isDummyDataRequest) => {
        getReportVisualisation({ variables: { reportId, dashboardId, globalFilters, isDummyDataRequest, externalFilters}});
    };
    // this useEffect is used to set data when autoRefresh API is working
    useEffect(() => {
        const data = reportsData[reportId];
        if(data && isAutoRefresh){
            setData(data);
        }
    },[reportsData, reportId]);
    const {dashboardId} = useParams();
    useEffect(() => {
        if(isTabletOrMobile && filterUpdated) {
            getReportVisualisationAPI(reportId, dashboardId, filters, enableDummyData);
            return;
        }
        if(lodashIsEmpty(reportsData)){
            getReportVisualisationAPI(reportId, dashboardId, filters, enableDummyData);
        }
        listenForRefresh((globalFilters) => {
            getReportVisualisationAPI(reportId, dashboardId, globalFilters, enableDummyData);
        }, dashboardTab);
    }, [enableDummyData, filterUpdated]);

    return {
        loaded,
        error,
        getReportVisualisation,
        chartType,
        chartData,
        chartOptions,
        fetchReport,
        formData,
        chartConfig,
        formatting,
        columnWidth,
        dateRange,
        visualizeMetaData,
        reportDescription,
        htmlData,
        reportType,
        newRelicUrl,
        lastRefreshTime,
    };
};

export default useChartContainerHook;
